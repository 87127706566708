import React from 'react';

import DialogView from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { LinkButton as ButtonClose } from '../';
import { AppTranslations } from '../../assets/translations';
import { PageLanguage } from '../../constants/types';

type Props = {
  children: any;
  onClickClose(): void;
  open: boolean;
  pageLanguage: PageLanguage;
  title?: string;
};

const Dialog: React.FC<Props> = (props) => {
  const { TEXT_BUTTON_CLOSE } = AppTranslations[props.pageLanguage].links;

  return (
    <DialogView
      open={props.open}
      onClose={props.onClickClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>

      <DialogContent dividers={true}>{props.children}</DialogContent>
      <DialogActions>
        <ButtonClose handleClick={props.onClickClose} text={TEXT_BUTTON_CLOSE} styleText position="end" noIcon />
      </DialogActions>
    </DialogView>
  );
};

export default Dialog;
