const getHeihElementById = (idCss: string): number => document.getElementById(idCss)?.clientHeight || 0;

const DocumentScrollIntoView = (idCss: string, position: 'center' | 'start' | 'end') => {
  if (idCss) document.getElementById(idCss)?.scrollIntoView({ behavior: 'smooth', block: position });
};

const scrollUp = () => DocumentScrollIntoView('root', 'start');
const scrollUpId = (idCss: string) => DocumentScrollIntoView(idCss, 'start');
const scrollCenterId = (idCss: string) => DocumentScrollIntoView(idCss, 'center');

const replaceUrlBrowser = (path: string, title = '') => window.history.replaceState('', title, path);

const BrowserUtils = {
  scrollUp,
  scrollUpId,
  scrollCenterId,
  getHeihElementById,
  replaceUrlBrowser
};

export default BrowserUtils;
