import React from 'react';
import { connect } from 'react-redux';

import { Select, FormControl, MenuItem } from '@material-ui/core';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

/**
 * SVG Flags
 * source: https://www.flaticon.com/free-icons/uk-flag
 */
import ES from '../../assets/icons/es.svg';
import FR from '../../assets/icons/fr.svg';
import US from '../../assets/icons/us.svg';
import PT from '../../assets/icons/br.svg';
import { PageLanguage } from '../../constants/types';
import { ApplicationState } from '../../store';
import { changeLanguage } from '../../store/ducks/pageLanguage';

import { TransitionEffect } from '..';

type LanguageIcon = {
  name: PageLanguage;
  icon: any;
};

type StateProps = {
  pageLanguage: PageLanguage;
};

type OwnProps = {
  id?: string;
  inTransitionEffect?: boolean;
  timeStartTransitionEffect?: number;
  timeExitTransitionEffect?: number;
  timeoutTransitionEffect?: number;
};

type DispatchProps = {
  changeLanguage(pageLanguage: PageLanguage): void;
};

type Props = OwnProps & DispatchProps & StateProps;

const Language: React.FC<Props> = ({
  changeLanguage,
  id = '',
  inTransitionEffect,
  timeStartTransitionEffect,
  timeExitTransitionEffect,
  timeoutTransitionEffect,
  pageLanguage,
}) => {
  const toggleLanguage = (event: unknown) => {
    const value = event && (event as PageLanguage) ? (event as PageLanguage) : 'EN';
    changeLanguage(value);
  };

  return (
    <TransitionEffect
      start={inTransitionEffect}
      timeout={timeoutTransitionEffect}
      timeStart={timeStartTransitionEffect}
      timeExit={timeExitTransitionEffect}
    >
      <Container id={id} className="Global-hover">
        <FormControl component="span" id="Languages-container" variant="outlined">
          <Select value={pageLanguage} onChange={(event) => toggleLanguage(event?.target?.value)}>
            {languages.map((l) => (
              <MenuItem key={l.name} value={l.name}>
                <img className="Global-Languages-item-icon" src={l.icon} alt={l.name} width="25px" height="25px" />
                {l.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    </TransitionEffect>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  pageLanguage: state.pageLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ changeLanguage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Language);

const languages: Array<LanguageIcon> = [
  {
    name: 'EN',
    icon: US,
  },
  {
    name: 'ES',
    icon: ES,
  },
  {
    name: 'FR',
    icon: FR,
  },
  {
    name: 'PT',
    icon: PT,
  },
];

const Container = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  .MuiSelect-root {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }

  .MuiSvgIcon-root {
    top: auto !important;
    right: 0 !important;
    opacity: 0.5;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;
