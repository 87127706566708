import styled, { css } from 'styled-components';

// ================================ Types ================================

type Props = {
  inheritColor?: boolean;
};

type FontStyle = {
  fontStyle?: 'italic' | 'normal';
};

type FontWeight = {
  fontWeight?: 'bold' | 'normal';
};

// ================================ STYLES ================================

const space = css`
  margin-bottom: 3%;
`;

// ================================ Less FOCUS

const TypographylessFocusColor = css`
  color: ${({ theme }) => theme.colors.disable};
`;

export const LessFocusSpan = styled.span<Props>`
  ${space};

  ${(props) => (props.inheritColor ? '' : TypographylessFocusColor)};
  font-weight: normal;
`;

// ================================ TITLES

export const Title = styled.h1<FontWeight>`
  font-size: 1.75rem;
  ${({ fontWeight }) => (fontWeight === 'normal' ? 'font-weight: unset;' : '')}
  line-height: 1.2rem;
`;

export const TitleSmall = styled.h2`
  font-size: 1.3rem;
  font-weight: normal;
`;

// ================================ SubTITLES
const subtitle = css`
  ${space};
`;

export const Subtitle = styled.h3<FontWeight>`
  ${subtitle};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'bold')};
`;

export const SubtitleMedium = styled.h4<FontWeight>`
  ${subtitle};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'bold')};
`;

export const SubtitleSmall = styled.h5`
  ${subtitle};
  ${({ theme }) =>
    theme.title === 'light'
      ? ''
      : `
        color: ${theme.colors.text.paragraphSecondary};
        font-weight: 100;
  `}
`;

// ================================ TOPICS

const topic = css`
  ${space};
  font-weight: normal;
`;

export const Topic = styled.h4<Props>`
  ${topic};

  ${(props) => (props.inheritColor ? '' : TypographylessFocusColor)};
`;

export const SubTopic = styled.h6<FontStyle>`
  ${topic};

  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : 'normal')};

  ${TypographylessFocusColor}
`;

// ================================ DATES

export const Date = styled.h4<{ inheritColor?: boolean; size?: 'small' | 'medium' }>`
  ${space}
  ${({ inheritColor }) => (inheritColor ? '' : TypographylessFocusColor)};
  ${({ size }) => (size === 'small' ? 'font-size: 0.8em;' : '')};
`;
