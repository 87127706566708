import React from 'react';

import styled from 'styled-components';

import { TransitionEffect } from '../../../components';

type Props = {
  children: Array<JSX.Element>;
  className?: string;
  idCss?: string;
};

const ListUL: React.FC<Props> = ({ idCss, children, className = '' }) => {
  return (
    <TransitionEffect>
      <Ul id={idCss} className={`Paper-section-content space-between ${className}`}>
        {children.map((child, index) => (
          <li key={index}>{child}</li>
        ))}
      </Ul>
    </TransitionEffect>
  );
};

export default ListUL;

const Ul = styled.ul`
  margin-left: 10%;
  word-spacing: 2px;
`;
