export const BREAK_CHARS_REGEX = /[%20]|[ ]|[, ]|[; ]|[,]|[;]|[ , ]|[ ; ]|[ - ]/;
export const SPACE_CHAR_CODE = "%20";

export const PROJECTS_BY_PAGE = {
  jobs: 8,
  github: 8,
  both: 10,
};

export const SUFFIXES_FOR_OPTIONS = {
  company: {
    EN: "company",
    ES: "empresa",
    FR: "entreprise",
    PT: "empresa",
  },
};
