import { AnyAction } from "redux";
import { put } from "redux-saga/effects";

import { PageLanguage, Skill, Workplace } from "../../../constants/types";
import Api from "../../../services/api";

import { loadSuccess, loadFailure } from "./actions";
export interface Payload extends AnyAction {
  payload: {
    pageLanguage: PageLanguage;
    workplace: Workplace;
  };
}

export function* loadSkills(action: Payload) {
  try {
    const { pageLanguage, workplace } = action.payload;
    let response: Array<Skill>;

    switch (workplace) {
      case "both":
        response = yield Api.getSkillsAll(pageLanguage);
        break;

      case "jobs":
        response = yield Api.getSkillsOfJobs(pageLanguage);
        break;

      case "github":
        response = yield Api.getSkillsOfGithub();
        break;

      default:
        response = [];
        break;
    }

    if (response.length < 1) throw new Error("Not found - empty");

    yield put(loadSuccess({ workplace, data: response }));
  } catch (err) {
    yield put(loadFailure());
  }
}
