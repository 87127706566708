import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Tabs as Menu, Tab as Label, MobileStepper } from '@material-ui/core';

import { AppTranslations } from '../../../assets/translations';
import { Skill, DisplayType, PageLanguage } from '../../../constants/types';
import { ApplicationState } from '../../../store';
import DisplayUtils from '../../../Utils/DisplayUtils';

type StateProps = {
  displayType: DisplayType;
};

type Props = {
  currentOption: number;
  idCss: string;
  onSelectOption: (newValue: number) => void;
  skills: Array<Skill>;
  pageLanguage: PageLanguage;
};

const Header: React.FC<StateProps & Props> = (props) => {
  const { HEADER } = AppTranslations[props.pageLanguage].components.skillDashboard;

  const isMobileDeviceMD = DisplayUtils.isMobileDeviceMD(props.displayType);
  const fixeScroll = isMobileDeviceMD ? 'on' : 'auto';

  if (props.currentOption >= props.skills.length) props.onSelectOption(0);

  return (
    <Fragment>
      <Menu
        id={props.idCss}
        component="header"
        value={props.currentOption >= props.skills.length ? 0 : props.currentOption}
        variant="scrollable"
        scrollButtons={fixeScroll}
      >
        {props.skills.map((skill, index) => (
          <Label key={index} value={index} label={HEADER[skill.name] || skill.name} onClick={() => props.onSelectOption(index)} />
        ))}
      </Menu>

      {isMobileDeviceMD && (
        <MobileStepper
          id="Skills-dashboard-header-stepps"
          variant="dots"
          steps={props.skills.length}
          position="static"
          activeStep={props.currentOption}
          nextButton={null}
          backButton={null}
          component="span"
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
});

export default connect(mapStateToProps)(Header);
