import React from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

const LoadingJobs: React.FC = (props) => {
  return (
    <Container>
      <Skeleton className="Jobs-loading-title" animation="wave" variant="rect" height="8%" width="100%" />
      <Skeleton className="Jobs-loading-content" animation="wave" variant="rect" height="25%" width="100%" />
      <Skeleton className="Jobs-loading-title" animation="wave" variant="rect" height="8%" width="100%" />
      <Skeleton className="Jobs-loading-content" animation="wave" variant="rect" height="25%" width="100%" />
      <Skeleton className="Jobs-loading-title" animation="wave" variant="rect" height="8%" width="100%" />
      <Skeleton animation="wave" variant="rect" height="25%" width="100%" />
    </Container>
  );
};

export default LoadingJobs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 45vh;
  max-height: 460px;
  min-height: 300px;
  width: 100%;

  .Jobs-loading-title {
    margin-bottom: 10px;
  }
  .Jobs-loading-content {
    margin-bottom: 20px;
  }
`;
