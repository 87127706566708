import React from "react";

import { PageLanguage, ProjectDTO, RouteType, Workplace } from "../../../../constants/types";
import { setTimeoutOnClick } from "../../../../Utils";
import RouterUtils from "../../../../Utils/RouterUtils";
import ProjectCard from "../ProjectCard";

type Props = {
  children?: never;
  index: number;
  navigate: (route: RouteType) => void;
  pageLanguage: PageLanguage;
  project: ProjectDTO;
  selectedCompanyFilter: string;
  selectedTechFilters: Array<string>;
  selectedYearFilter: string;
  workplace: Workplace;
};

const ProjectContainer: React.FC<Props> = (props) => {
  const prjNavigation = buildProjectNavigationReference(props.project, props.navigate);

  return (
    <ProjectCard
      href={prjNavigation.href}
      hrefTitle={prjNavigation.hrefTitle}
      onClick={prjNavigation.navigate}

      index={props.index}
      pageLanguage={props.pageLanguage}
      project={props.project}
      selectedCompanyFilter={props.selectedCompanyFilter}
      selectedTechFilters={props.selectedTechFilters}
      selectedYearFilter={props.selectedYearFilter}
      workplace={props.workplace}
    />
  );
};

export default ProjectContainer;

const buildProjectNavigationReference = (project: ProjectDTO, navigate: (route: RouteType) => void) => {
  if (project.origin === "GITHUB") {
    return {
      href: project.refLink,
      hrefTitle: project.refLink,
      navigate: () => window.open(project.refLink, "_blank"),
    };
  }

  const route = RouterUtils.getRouteProjectDetailsWithId(project.id);

  return {
    href: route.path,
    hrefTitle: window.origin + route.path,
    navigate: () => setTimeoutOnClick(() => navigate(route), 500),
  };
};
