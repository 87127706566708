import styled, { css } from 'styled-components';

import { COLOR_30_PERCENT, COLOR_70_PERCENT } from '../../styles/themes';

const cssMediaQueries = css`
  @media (max-width: 1024px) {
    .Job-header {
      .Job-header-container {
        flex-flow: wrap;
      }
    }
  }

  @media (max-width: 768px) {
    .Job-header {
      padding-left: 1px;
    }
    .MuiStepLabel-iconContainer {
      padding-left: 1.5px;
    }
  }

  @media (max-width: 680px) {
    .Job-header {
      margin-bottom: 2px;
      padding-left: 2px;
    }
  }

  @media (max-width: 600px) {
    .Job-container-content {
      padding-top: 0;
    }
  }

  @media (max-width: 500px) {
    .Job-title {
      margin-left: 0%;
      display: flex;
      flex-wrap: wrap;
    }
    .Job-container-content {
      margin-top: 0 !important;
      padding-right: 0;
    }
  }

  @media (max-width: 320px) {
    .Job-container-content {
      padding-bottom: 3em;
    }
  }
`;

export const Container = styled.section`
  width: 100%;

  #Jobs-container {
    background: inherit !important;
  }

  .MuiPaper-root {
    background-color: inherit;
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .Job-container-content {
    margin-top: 0 !important;
    padding-right: 0;
    padding-top: 1em;
    padding-bottom: 1.5%;

    #Job-step-content-nextjobbutton {
      margin-top: 2%;
    }
  }

  .MuiStepConnector-vertical {
    padding-bottom: 0;
  }

  .Jobs-item,
  .MuiStepConnector-root {
    border-radius: 5px;
    padding: 0 4px;
  }
  .Jobs-item:hover {
    .hover-effect,
    .Job-time {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary.light} !important;
    }

    .MuiSvgIcon-root {
      opacity: 1;
      fill: ${({ theme }) => theme.colors.primary.light};
    }
  }

  .MuiStepLabel-active,
  .MuiStepLabel-active .Job-time {
    color: ${({ theme }) => theme.colors.primary.light} !important;
  }

  .MuiStepLabel-label {
    color: ${({ theme }) => theme.colors.disable};
  }

  .MuiStepLabel-iconContainer {
    svg {
      font-size: 1.25rem !important;
    }
  }

  ${cssMediaQueries}
`;

export const IconUnChecked = styled.span<{ active: boolean }>`
  display: contents;

  svg {
    fill: ${(props) => (props.active ? props.theme.colors.primary.light : props.theme.colors.disable)};
  }

  path {
    fill: ${(props) =>
      props.active ? props.theme.colors.primary.light.concat(COLOR_30_PERCENT) : props.theme.colors.disable.concat(COLOR_70_PERCENT)};
  }
`;