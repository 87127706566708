import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { bindActionCreators, Dispatch } from "redux";

import { Error } from "../";
import { AppTranslations } from "../../assets/translations";
import { PageLanguage, RankingSkills, Workplace } from "../../constants/types";
import { ApplicationState } from "../../store";
import { loadRequest } from "../../store/ducks/ranking/actions";
import ObjectUtils from "../../Utils/ObjectUtils";
import StoreUtils from "../../Utils/StoreUtils";

import LoadingRanking from "./components/Loading";
import {
  buildOptionsBy,
  orderRankingClassificationBy,
  updateStateToInitialStateWithTheCurrentSelectedOption,
  updateStateWithCurrentOptionSelectedByLanguage,
  updateStateWithTheNewRankingData,
} from "./helpers";
import RakingView from "./RankingView";
import { StateType, StateTypeOptionEnum } from "./types";

type StateProps = {
  rankingData: RankingSkills | null;
  pageLanguage: PageLanguage;
  workplace: Workplace;
  rankingLoading: boolean;
  rankingError: boolean;
};

type DispatchProps = {
  loadRequest(workplace: Workplace): void;
};

type OwnProps = {
  className?: string;
  homepage?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

const Ranking: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState<StateType>(initialState(props));

  const stateIsEmpty = StoreUtils.StateIsEmpty({ data: props.rankingData, error: props.rankingError, loading: props.rankingLoading });

  const loadRequestData = () => props.loadRequest(props.workplace);
  const handleSelect = (option = "") => sortTheClassificationRankingByTheOptionSelected(option, state, setState, props.pageLanguage);

  useEffect(() => {
    updateStateToInitialStateWithTheCurrentSelectedOption(initialState(props), setState);
  }, [props.workplace]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateStateWithCurrentOptionSelectedByLanguage(props.pageLanguage, setState);
  }, [props.pageLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  if (stateIsEmpty) loadRequestData();
  else if (!state.ranking && props.rankingData) updateStateWithTheNewRankingData(props.rankingData, setState);

  if (props.rankingError) return <Error onClickTryAgain={loadRequestData} />;
  else if (props.rankingLoading || stateIsEmpty || state.ranking === null) return <LoadingRanking />;

  return (
    <RakingView
      isHomePage={props.homepage}
      className={props.className}
      ranking={state.ranking}
      pageLanguage={props.pageLanguage}
      handleSelectOrderBy={handleSelect}
      selectOderByOption={state.options.selected.label[props.pageLanguage]}
    />
  );
};

export const initialState = (props: Props): StateType => ({
  ranking: ObjectUtils.clone(props.rankingData),
  rankingOrderByDefault: ObjectUtils.clone(props.rankingData),
  options: {
    values: buildOptionsBy(props.pageLanguage),
    selected: {
      key: StateTypeOptionEnum.RECENTLY,
      label: {
        [props.pageLanguage]: AppTranslations[props.pageLanguage].components.ranking.selectButton.options.RECENTLY,
      },
    },
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  rankingData: state.RankingSkills.data[state.workplace],
  rankingLoading: state.RankingSkills.loading,
  rankingError: state.RankingSkills.error,
  pageLanguage: state.pageLanguage,
  workplace: state.workplace,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);

const sortTheClassificationRankingByTheOptionSelected = (
  option: string,
  state: StateType,
  setState: React.Dispatch<React.SetStateAction<StateType>>,
  pageLanguage: PageLanguage
) => {
  const optionSelected = state.options.values.find((e) => e.label[pageLanguage] === option)?.key || StateTypeOptionEnum.RECENTLY;

  switch (optionSelected) {
    case StateTypeOptionEnum.STARS:
      state.ranking && orderRankingClassificationBy("stars", optionSelected, state.ranking, setState);
      break;
    case StateTypeOptionEnum.PROJECTS:
      state.ranking && orderRankingClassificationBy("projects", optionSelected, state.ranking, setState);
      break;
    default:
      setState((prev) => ({ ...prev, ranking: ObjectUtils.clone(prev.rankingOrderByDefault), orderBy: option }));
      break;
  }
};
