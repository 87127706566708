import React, { ElementType } from 'react';

import { SvgIconProps } from '@material-ui/core';
import Button from '@material-ui/core/ListItem';
import ContainerIcon from '@material-ui/core/ListItemIcon';
import Text from '@material-ui/core/ListItemText';

import { setTimeoutOnClick } from '../../Utils';

type Props = {
  className?: string;
  id?: string;
  icon: ElementType<SvgIconProps>;
  onClick: () => void;
  text: string;
  disable?: boolean;
};

const MenuButton: React.FC<Props> = ({ disable = false, id = '', className = '', icon: Icon, onClick, text }) => {
  return (
    <Button
      id={id}
      className={className}
      disabled={disable}
      button
      key={text}
      component="button"
      onClick={() => setTimeoutOnClick(onClick)}
    >
      <ContainerIcon className="Menu-nav-button-icon">
        <Icon />
      </ContainerIcon>
      <Text className="Menu-nav-button-text" primary={text} />
    </Button>
  );
};

export default MenuButton;
