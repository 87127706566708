import { ROUTES } from '../constants/routesConstants';
import { RouteType } from '../constants/types';

const getQueryComponentsFromUrl = (params: string): Array<string> => {
  const startQueryParams = params.indexOf('?');
  const param = startQueryParams > 0 ? params.substr(startQueryParams + 1) : '';
  return param.split('&');
};

const getFirstQueryComponentFromUrl = (params: string): string => {
  const startQueryParams = params.indexOf('=');
  const param = startQueryParams > 0 ? params.substr(startQueryParams + 1) : '';
  return param;
};

const getIDFromLastPath = (path: string) => {
  const indexLastSlash = path.lastIndexOf('/');
  if (indexLastSlash === -1) return '';
  return path.substring(indexLastSlash + 1, path.length);
};

const getRouteProjectDetailsWithId = (projectId: string): RouteType => ({
  ...ROUTES.WORK_EXPERIENCE_PROJECTS_DETAILS,
  path: `${ROUTES.WORK_EXPERIENCE_PROJECTS_DETAILS.path.replace(':projectId', projectId)}`,
});

const getRouteBlogPostWithId = (postId: string): RouteType => ({
  ...ROUTES.BLOG_POST,
  path: `${ROUTES.BLOG_POST.path.replace(':postId', postId)}`,
});

const getRouteProjectsWithTechFilter = (filter: string): RouteType => ({
  ...ROUTES.WORK_EXPERIENCE_PROJECTS_FILTER,
  path: `${ROUTES.WORK_EXPERIENCE_PROJECTS_FILTER.path.concat(`tech=${filter.toLowerCase()}`)}`,
});

const getRouteJobId = (jobId: string): RouteType => ({
  ...ROUTES.WORK_EXPERIENCE_JOBS_JOB_DETAILS,
  path: `${ROUTES.WORK_EXPERIENCE_JOBS_JOB_DETAILS.path.replace(':jobId', jobId)}`,
});

const getIdInUrl = (path: string): string => {
  const paths = path.split('/');
  return paths[paths.length - 1];
};

const RouterUtils = {
  getQueryComponentsFromUrl,
  getFirstQueryComponentFromUrl,
  getIdInUrl,
  getRouteJobId,
  getRouteProjectDetailsWithId,
  getRouteProjectsWithTechFilter,
  getIDFromLastPath,
  getRouteBlogPostWithId
};

export default RouterUtils;
