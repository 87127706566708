import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../constants/routesConstants';
import CommonPages from '../pages/common';
import Home from '../pages/home/HomePage';
import RouteRedirector from '../pages/redirector';

type RouteType = {
  id: string;
  name: string;
  path: string;
};

const Routes: React.FC = () => {
  const routes = Object.values(ROUTES);

  const getRouteComponent = (route: RouteType, i: number) => {
    if (route.path === ROUTES.HOME.path) {
      return <Route key={i} exact path={route.path} component={Home} />;
    }
    return (
      <Route
        key={i}
        exact
        path={route.path}
        render={({ location }) => <CommonPages currentUrl={`${location.pathname}${location.search}`} routeId={route.id} />}
      />
    );
  };

  return (
    <Switch>
      {routes.map((route, i) => getRouteComponent(route, i))}

      <Route path="/skills" exact render={() => <Redirect to={ROUTES.WORK_EXPERIENCE_SKILLS.path} />} />

      <Route
        path="/projects"
        exact
        render={({ location }) => <Redirect to={ROUTES.WORK_EXPERIENCE.path.concat(location.pathname + location.search)} />}
      />

      <Route
        path="/experience"
        exact
        render={({ location }) => <Redirect to={ROUTES.WORK_EXPERIENCE_JOBS.path} />}
      />

      <Route
        path={ROUTES.BLOG.path}
        render={({ location }) => <CommonPages currentUrl={`${location.pathname}${location.search}`} routeId={ROUTES.BLOG.id} />}
      />

      <Route path="/in/" render={({ location }) => <RouteRedirector urlPath={location.pathname + location.search} />} />

      <Route path="*" component={Home} />
    </Switch>
  );
};

export default Routes;
