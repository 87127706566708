import React, { Fragment } from "react";

import { Card, CardHeader, CardActionArea, CardMedia, Zoom } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled, { css } from "styled-components";

import { AppTranslations } from "../../../assets/translations";
import { Button } from "../../../components";
import { GitHubIcon, BusinessIcon, NewTabIcon } from "../../../components/Icons";
import { SubtitleSmall as Tag } from "../../../components/Typography";
import { ProjectDTO, PageLanguage, Workplace } from "../../../constants/types";
import StringUtils from "../../../Utils/StringUtils";

import Date from "./components/Date";
import SelectedText from "./components/SelectedText";

type Props = {
  href?: string;
  hrefTitle?: string;
  index: number;
  onClick?: () => void;
  pageLanguage: PageLanguage;
  project: ProjectDTO;
  selectedCompanyFilter: string;
  selectedTechFilters: Array<string>;
  selectedYearFilter: string;
  workplace: Workplace;
};

const ProjectCard: React.FC<Props> = ({
  href,
  hrefTitle,
  index,
  pageLanguage,
  project,
  onClick,
  selectedCompanyFilter,
  selectedTechFilters,
  selectedYearFilter,
  workplace,
}) => {
  return (
    <Zoom key={index} in={true} timeout={parseInt(`1${index + 1}00`)}>
      <Container className="Cards" reduceIntro={workplace === "jobs"} isGithubWorkPlace={project.origin === "GITHUB"}>
        <Card component="section" raised={true}>
          <CardActionArea>
            <Fragment>
              <a className="Card-open-new-tab" href={href} title={hrefTitle} target="_blank" rel="noopener noreferrer">
                <Button component="span" className="Card-open-new-tab-icon" icon={NewTabIcon} />
              </a>
              <CardHeader
                id="Card-header"
                className="Card-content"
                component="header"
                title={project.title}
                subheader={
                  <SubHeader project={project} pageLanguage={pageLanguage} company={selectedCompanyFilter} year={selectedYearFilter} />
                }
                onClick={onClick}
              />

              <figure>
                {project.origin === "GITHUB" && <GitHubIcon className="Card-image-category-icon" />}
                {project.origin !== "GITHUB" && <BusinessIcon className="Card-image-category-icon" />}
                {project.cardImageLink && (
                  <CardMedia
                    component="img"
                    className="Card-image"
                    alt="Contemplative Reptile"
                    image={project.cardImageLink}
                    title="Contemplative Reptile"
                    onClick={onClick}
                  ></CardMedia>
                )}
              </figure>

              <Typography
                onClick={onClick}
                id="Card-text-intro"
                className="Card-content"
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {project.introduction}
              </Typography>

              <Footer projectIndex={index} project={project} techFilters={selectedTechFilters} onClick={onClick} />
            </Fragment>
          </CardActionArea>
        </Card>
      </Container>
    </Zoom>
  );
};

export default ProjectCard;

const SubHeader = (props: { project: ProjectDTO; company: string; year: string; pageLanguage: PageLanguage }) => (
  <div className="Card-header-sub">
    <span style={{ marginRight: "10px" }}>
      {props.project.origin === "COMPANY" ? <CompanyLabel pageLanguage={props.pageLanguage} /> : ""}
      {props.company === props.project.company ? <SelectedText text={props.project.company} /> : props.project.company}
    </span>
    <Date
      filter={props.year}
      pageLanguage={props.pageLanguage}
      projectStart={props.project.projectStart}
      projectEnd={props.project.projectEnd}
    />
  </div>
);

const Footer = (props: { project: ProjectDTO; projectIndex: number; techFilters: Array<string>; onClick?: () => void }) => (
  <footer onClick={props.onClick} className="Card-content">
    {props.project.tags.map((tag, tagIndex) => (
      <Tag key={props.projectIndex + tagIndex} className={`Tags Card-filter-selected-${isTagSelected(tag, props.techFilters)}`}>
        {`#${tag.replace(/ /g, "")}`}
      </Tag>
    ))}
  </footer>
);

const CompanyLabel = (props: { pageLanguage: PageLanguage }) => (
  <span style={{ opacity: "0.6" }}>{AppTranslations[props.pageLanguage].components.projects.companyLabel}</span>
);

const isTagSelected = (tag: string, filters: Array<string>) => filters.length > 0 && StringUtils.containsAtList(tag, filters);

const effectMinimizeIntro = css`
  max-height: 180px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.article<{ reduceIntro: boolean; isGithubWorkPlace: boolean }>`
  width: 48%;
  margin: 1%;

  .Card-image-category-icon,
  .MuiPaper-root,
  .MuiPaper-root p {
    background: ${({ theme }) => theme.colors.background.primaryStrong} !important;
  }

  button {
    font: inherit;
  }

  .Card-open-new-tab {
    position: absolute;
    right: 0;
    width: fit-content;
    .Card-open-new-tab-icon {
      padding: 5px;
      color: ${({ theme }) => theme.colors.disable};
      :hover {
        color: ${({ theme }) => theme.colors.primary.dark};
      }
    }
  }

  .MuiCardHeader-subheader {
    font-size: 0.8rem;
  }

  .MuiCardHeader-title {
    font-size: 1.3rem;
  }

  #Card-text-intro {
    padding-bottom: 0;
    ${(props) => (props.reduceIntro ? effectMinimizeIntro : "")}
  }

  .Card-image {
    max-width: -webkit-fill-available;
    margin-left: auto;
    margin-right: auto;
  }

  .Card-image-category-icon {
    position: absolute;
    padding: 5px 10px 10px 5px;
    width: 1.5em;
    height: 1.5em;

    border: solid ${({ theme }) => theme.colors.background.primaryStrong};
    border-left: 0;
    border-top: 0;

    color: ${({ theme, isGithubWorkPlace }) => isGithubWorkPlace ? theme.colors.disable : theme.colors.text.subTitle};
  }

  #Card-header {
    .Card-header-sub {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 5px;
      margin-left: 5px;
    }
    .MuiCardHeader-title {
      width: 90%;
    }
  }

  .Card-content {
    padding: 2% 5%;
  }

  .Card-filter-selected-true,
  .Card-filter-selected-false {
    border: solid 1px transparent;
    border-radius: 5px;
    padding: 0px 5px;
  }

  .Card-filter-selected-true {
    color: ${({ theme }) => theme.colors.primary.light} !important;
    border-color: ${({ theme }) => theme.colors.primary.light};
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
    padding-top: 0;
    .Tags {
      padding: 0px 4px;
      margin: 0;
      color: ${({ theme }) => (theme.title === "light" ? theme.colors.text.paragraphSecondary : theme.colors.text.subTitle)};
    }
  }

  @media (max-width: 680px) {
    width: 100%;

    .Card-image {
      max-width: 100%;
    }
  }
`;
