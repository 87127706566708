import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';

import { PageLanguage } from '../../../constants/types';

// TYPES
export const Types = {
  CHANGE: '@pageLanguage/CHANGE_RESQUEST',
};

// ACTIONS:
interface Action extends AnyAction {
  payload: PageLanguage;
}
export const changeLanguage = (pageLanguage: PageLanguage) => action(Types.CHANGE, pageLanguage);

// INITAL STATE
const getBrowserLanguage = (): PageLanguage => {
  const pageLanguage = window.navigator.language;
  const languageDetect = pageLanguage.slice(0, pageLanguage.indexOf('-')).toUpperCase();
  switch (languageDetect) {
    case 'EN':
      return 'EN';
    case 'PT':
      return 'PT';
    case 'ES':
      return 'ES';
    case 'FR':
      return 'FR';
    default:
      return 'EN';
  }
};

const INITIAL_STATE: PageLanguage = getBrowserLanguage();

// REDUCER
const PageLanguageReducer: Reducer<PageLanguage, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.CHANGE:
      return action.payload;
    default:
      return state;
  }
};

export default PageLanguageReducer;
