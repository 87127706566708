import React, { Fragment } from 'react';

import { PagesTranslations } from '../../../../assets/translations';
import { SubtitleSmall as Text, LessFocusSpan } from '../../../../components/Typography';
import { Language, PageLanguage } from '../../../../constants/types';
import ListUL from '../ListUL';

type Props = {
  className?: string;
  languages: Array<Language>;
  pageLanguage: PageLanguage;
};

const LanguagesView: React.FC<Props> = ({ pageLanguage, className = '', languages }) => {
  const { TEXT_CERTIFICATE } = PagesTranslations[pageLanguage].EDUCATION;

  const buildLanguagesLi = () =>
    languages.map((language, i) => (
      <Text key={i}>
        {language.name}
        <LessFocusSpan className="Less-focus">
          {` - ${language.level}`}
          {language.certificateLink && (
            <Fragment>
              {' ('}
              <a href={language.certificateLink} rel="noopener noreferrer" target="_blank" title={`Donwload ${TEXT_CERTIFICATE}`}>
                {TEXT_CERTIFICATE}
              </a>
              )
            </Fragment>
          )}
        </LessFocusSpan>
      </Text>
    ));

  return <ListUL children={buildLanguagesLi()} className={className} />;
};

export default LanguagesView;
