import React from 'react';

import { Step, StepContent, Stepper, StepIconProps, StepLabel } from '@material-ui/core';
import { RadioButtonCheckedOutlined } from '@material-ui/icons';

import { WorkExperience, PageLanguage } from '../../constants/types';

import JobContent from './components/JobContent';
import JobHeader from './components/JobHeader';
import { Container, IconUnChecked } from './styles';

import { TransitionEffect } from '..';

type Props = {
  currentJob: number;
  expandContent: boolean;
  handleClickJob: (jobId: string, jobIndex: number) => void;
  handleExpandContent: () => void;
  isMobilePhone: boolean;
  jobs: Array<WorkExperience>;
  homepage?: boolean;
  pageLanguage: PageLanguage;
  toggleJob: (jobId: string, jobIndex: number) => void;
};

const JobsView: React.FC<Props> = (props) => {
  const { expandContent, isMobilePhone, jobs, homepage = false, currentJob, pageLanguage } = props;

  const IconRadion = ({ active = false }: StepIconProps) => (
    <IconUnChecked active={active}>
      <RadioButtonCheckedOutlined />
    </IconUnChecked>
  );

  return (
    <TransitionEffect>
      <Container>
        <Stepper id="Jobs-container" component="ul" activeStep={currentJob} nonLinear orientation="vertical">
          {jobs.map((job: WorkExperience, index) => (
            <Step
              id={`Job-${index}`}
              className="Jobs-item"
              key={index}
              expanded={homepage}
              onClick={() => props.handleClickJob(job.id, index)}
            >
              <StepLabel StepIconComponent={IconRadion}>
                <JobHeader homepage={homepage} job={job} isMobilePhone={isMobilePhone} pageLanguage={pageLanguage} />
              </StepLabel>

              <StepContent className="Job-container-content">
                <JobContent
                  expandContent={expandContent}
                  handleExpandContent={props.handleExpandContent}
                  isActived={currentJob === index}
                  isLastJob={index === jobs.length - 1}
                  isMobilePhone={isMobilePhone}
                  job={job}
                  jobIndex={index}
                  homepage={homepage}
                  pageLanguage={pageLanguage}
                  toggleJob={props.toggleJob}
                />
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>
    </TransitionEffect>
  );
};

export default JobsView;
