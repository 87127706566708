import React from 'react';

import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

type Props = {
  onClick: () => void;
};

const ChatButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Fab color="primary" aria-label="add" onClick={onClick}>
      <AddIcon />
    </Fab>
  );
};

export default ChatButton;
