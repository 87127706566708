import React, { Fragment } from "react";

import { Pagination } from "../../../../components";
import { PageLanguage, ProjectDTO, RouteType, Workplace } from "../../../../constants/types";

import ProjectContainer from "./ProjectContainer";

type Props = {
  children?: never;
  navigate: (route: RouteType) => void;
  pageLanguage: PageLanguage;
  paginationElementsPerPage: number;
  paginationHandleClick: (page: number) => void;
  paginationPage: number;
  paginationProjectsPerPage: number;
  paginationTotalElements: number;
  projects: Array<ProjectDTO>;
  selectedCompanyFilter: string;
  selectedTechFilters: Array<string>;
  selectedYearFilter: string;
  workplace: Workplace;
};

const ProjectsContainer: React.FC<Props> = (props) => {
  return (
    <Fragment>
      <ul id="Projects-list">
        {props.projects.map((p, i) => (
          <ProjectContainer
            index={i}
            key={p.id + i}
            navigate={props.navigate}
            pageLanguage={props.pageLanguage}
            project={p}
            selectedCompanyFilter={props.selectedCompanyFilter}
            selectedTechFilters={props.selectedTechFilters}
            selectedYearFilter={props.selectedYearFilter}
            workplace={props.workplace}
          />
        ))}
      </ul>
      <Pagination
        id="Projects-last-pagination"
        elementsPerPage={props.paginationProjectsPerPage}
        handleClick={props.paginationHandleClick}
        page={props.paginationPage}
        totalElements={props.paginationTotalElements}
      />
    </Fragment>
  );
};

export default ProjectsContainer;
