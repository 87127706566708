import React, { Fragment } from 'react';

import styled from 'styled-components';

import { Language, Divider } from '../../..';
import { MenuButton } from '../../../';
import { ThemeButton, Button as MenuClose } from '../../../';
import { ArrowCloseMenuIcon, MenuIcon } from '../../../Icons';

type Props = {
  isDrawerOpen: boolean;
  openDrawerMenu: () => void;
  closeDrawerMenu: () => void;
};

/* 
  <span>: is important about active styles for small devices.
*/

const ToolbarDrawer: React.FC<Props> = ({ openDrawerMenu, closeDrawerMenu, isDrawerOpen }) => {
  return (
    <Fragment>
      <Container id="Drawer-toolbar">
        {!isDrawerOpen && <MenuButton id="Drawer-toolbar-button-menuopen" onClick={openDrawerMenu} icon={MenuIcon} text="" />}

        {isDrawerOpen && <Language id="Drawer-toolbar-languages" />}
        {isDrawerOpen && <ThemeButton id="Drawer-toolbar-theme" />}

        {isDrawerOpen && <MenuClose id="Drawer-toolbar-button-menuclose" onClick={closeDrawerMenu} icon={ArrowCloseMenuIcon} />}
      </Container>
      <Divider id="Drawer-divider-toolbar" position="horizontal" />
    </Fragment>
  );
};

export default ToolbarDrawer;

export const Container = styled.header`
  display: grid;
  grid-template-columns: 35% 30% 35%;
  place-items: center;

  #Drawer-toolbar-button-menuopen {
    padding-right: 3vw !important;
    height: 100%;
  }

  #Drawer-toolbar-languages {
    justify-self: start;
  }
  #Drawer-toolbar-button-menuclose {
    justify-self: end;
  }

  #Drawer-toolbar-button-menuopen,
  #Drawer-toolbar-button-menuclose {
    grid-area: menuIconOpenClose;
  }
  grid-template-areas: 'menuIconOpenClose menuIconOpenClose menuIconOpenClose';

  @media (max-width: 500px), (max-height: 700px) {
    #Drawer-toolbar-button-menuclose {
      grid-area: auto;
    }
  }
`;
