import React from "react";

import { FormControl, MenuItem, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { SvgIconComponent } from "@material-ui/icons";
import styled from "styled-components";

import { LessFocusSpan } from "../Typography";

export type OptionsType = Array<{
  value: string;
  suffix?: string;
  icon?: SvgIconComponent;
}>;

type Props = {
  idCss?: string;
  className?: string;
  label: any;
  value: any;
  options: OptionsType;
  handleSelect: (value?: string) => void;
  disabled?: boolean;
};

const OptionSelect: React.FC<Props> = ({ disabled, idCss = "", className = "", label, value, options, handleSelect }) => {
  return (
    <FormContainer id={idCss} variant="outlined" className={className} disabled={disabled}>
      <InputLabel id="demo-simple-select-required-label">{label}</InputLabel>
      <Select value={value} onChange={(e) => handleSelect(e.target.value as string)} label={label}>
        {options.map((option) => (
          <MenuItemStyled key={option.value} value={option.value}>
            {option.icon && <IconContainer Icon={option.icon} />}
            <p>{option.value}</p>
            {option.suffix && <Suffix key={option.value} text={option.suffix} />}
          </MenuItemStyled>
        ))}
      </Select>
    </FormContainer>
  );
};

export default OptionSelect;

const Suffix = (props: { text: string }) => (
  <LessFocusSpan className="prefix" style={{ fontSize: "0.7rem", margin: "0 0 0 auto", fontStyle: "italic" }}>
    ( {props.text} )
  </LessFocusSpan>
);

const IconContainer = ({ Icon }: { Icon: SvgIconComponent }) => <Icon />;

const FormContainer = styled(FormControl)`
  .MuiInputBase-input {
    & > svg,
    .prefix {
      display: none;
    }
  }
`;

const MenuItemStyled = styled(MenuItem)`

  & > p {
    margin: 0 10px;
  }

  .MuiMenu-list {
    & > svg,
    .prefix {
      display: initial !important;
    }
  }

  svg {
    width: 0.9em;

    color: ${({ theme }) => theme.colors.disable};
    opacity: 0.7;
  }
`;
