import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import { PageLanguage, Blog } from '../../../constants/types';
import Api from '../../../services/api';

import { loadSuccess, loadFailure } from './actions';

type BlogDTO = {
  year: string;
  posts: Array<{
    id: string;
    title: string;
    type: 'ARTICLE' | 'TALK' | 'EVENT';
    imageLink: string;
    externalLink?: string;
    isExternalPost: boolean;
    date: string;
  }>;
};

export interface Payload extends AnyAction {
  payload: PageLanguage;
}

export function* loadBlog(action: Payload) {
  try {
    const pageLanguage = action.payload;
    const response: Array<BlogDTO> = yield Api.getBlog(pageLanguage);

    if (response.length < 1) throw new Error('Not found - empty');

    const convertToBlog = (v: BlogDTO): Blog => ({
      year: v.year,
      posts: v.posts.map((p) => ({ ...p, date: new Date(p.date) })),
    });

    yield put(loadSuccess({ pageLanguage, blog: response.map(convertToBlog) }));
  } catch (err) {
    yield put(loadFailure());
  }
}
