import React from "react";

import { Tooltip } from "@material-ui/core";
import styled, { DefaultTheme } from "styled-components";

type Props = {
  color?: string;
  percentageValue: number;
  tooltipClassName: string;
  toolTipMessage: string;
  total: number;
  value: number;
};

const Slash: React.FC<Props> = ({ percentageValue, total, value, color, toolTipMessage, tooltipClassName }) => {
  return (
    <Tooltip title={toolTipMessage} arrow placement="top" classes={{ tooltip: tooltipClassName }}>
      <LoaderSlash value={percentageValue} color={color}>
        <span id="Slash-value">{value}</span>
        {value !== total && <span id="Slash-total">{total}</span>}
      </LoaderSlash>
    </Tooltip>
  );
};

export default Slash;

type LoaderSlashType = {
  value: number;
  color?: string;
};

const getColor = (props: { color?: string; theme: DefaultTheme }) => (props.color ? props.color : props.theme.colors.primary.dark);

export const LoaderSlash = styled.figure<LoaderSlashType>`
  display: flex;
  flex-direction: row;
  text-align: center;
  border: 2px solid ${getColor};
  border-radius: 4px;
  font-weight: bold;

  #Slash-value {
    width: ${({ value }) => value}%;
    border-radius: 0px 20px 20px 0px;
    background: ${getColor};
    color: ${({ theme }) => (theme.title === "dark" ? theme.colors.text.paragraph : theme.colors.background.primary)};
    text-shadow: 0px 1px 2px
      ${({ theme }) => (theme.title === "dark" ? theme.colors.text.paragraphSecondary : theme.colors.background.secondary)};
  }

  #Slash-total {
    width: ${({ value }) => 100 - value}%;
    color: ${({ theme }) => theme.colors.text.title};
  }
`;
