import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { DisplayType, RouteType } from '../../../constants/types';
import { ApplicationState } from '../../../store';
import DisplayUtils from '../../../Utils/DisplayUtils';

import DrawerMenuView from './DrawerMenuView';

type StateNavigateMenu = {
  menuIsOpen: boolean;
};

type StateProps = {
  displayType: DisplayType;
  idCss?: string;
};

type OwnProps = {
  navigate: (route: RouteType) => void;
};

type Props = StateProps & OwnProps;

const DrawerMenu: React.FC<Props> = ({ displayType, idCss, navigate }) => {
  const [state, setState] = useState<StateNavigateMenu>(initialState(displayType));

  const isMobileDevice = DisplayUtils.isMobileDevice(displayType);
  const isMobilePhoneSM = DisplayUtils.isMobilePhoneSM(displayType);

  const openDrawerMenu = () => {
    if (!state.menuIsOpen) {
      setState({ menuIsOpen: true });
      document.body.style.overflowY = 'hidden';
    }
  };

  const closeDrawerMenu = () => {
    if (isMobileDevice && state.menuIsOpen) {
      setState({ menuIsOpen: false });
      document.body.style.overflowY = 'scroll';
    }
  };

  const starWithMenuOpen = (isMobileDevice: boolean, menuIsOpen: boolean): void => {
    isMobileDevice === menuIsOpen && setState((prev) => ({ ...prev, menuIsOpen: !prev.menuIsOpen }));
  };

  // Never add open [isMobileDevice, open], because can became of result a bug in open menu
  useEffect(() => starWithMenuOpen(isMobileDevice, state.menuIsOpen), [isMobileDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DrawerMenuView
      closeDrawerMenu={closeDrawerMenu}
      openDrawerMenu={openDrawerMenu}
      idCss={idCss}
      isMobileDevice={isMobileDevice}
      isMobilePhoneSM={isMobilePhoneSM}
      isDrawerOpen={state.menuIsOpen}
      navigate={navigate}
    />
  );
};

const initialState = (displayType: DisplayType): StateNavigateMenu => ({
  menuIsOpen: !DisplayUtils.isMobileDevice(displayType),
});

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
});

export default connect(mapStateToProps)(DrawerMenu);
