import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { DefaultTheme } from 'styled-components';

const PRIMARY_LIGHT = '#ff2d31';
const PRIMARY_MAIN = '#e5383b';
const PRIMARY_DARK = '#d0363a';
const PRIMARY_AUX = '#791d1f';
const TEXT_SUBTITLE = '#e05252';
const BACKGROUND_PRIMARY = '#fff';
const BACKGROUND_SECONDARY = '#d2d2d2';
const TEXT_PRIMARY = 'rgb(10, 10, 10, 1)';
const TEXT_SECONDARY = 'rgb(10, 10, 10, 0.6)';
const DISABLED = 'rgb(10, 10, 10, 0.50)';
const BORDER = '#bbbbbb';
const HOVER = 'rgb(10, 10, 10, 0.1)';
const BACKGROUND_DISABLED = 'rgb(10, 10, 10, 0.7)';

/* Gradiente for menu has be rgb with copy color of background */
const GRADIENT_EFFECT_START = 'rgb(255, 255, 255, 1)';
const GRADIENT_EFFECT_END = 'rgb(255, 255, 255, 0.1)';
const GRADIENT_EFFECT_START_DESKTOP: string | undefined = 'rgb(210, 210, 210, 1)';
const GRADIENT_EFFECT_END_DESKTOP: string | undefined = 'rgb(210, 210, 210, 0.1)';

const primary = {
  light: PRIMARY_LIGHT,
  dark: PRIMARY_DARK,
  main: PRIMARY_MAIN,
};

const styled = (color30Percent: string): DefaultTheme => ({
  title: 'light',
  colors: {
    primary,

    primaryAux: PRIMARY_AUX,

    disable: DISABLED,

    background: {
      primary: BACKGROUND_PRIMARY,
      primaryStrong: '#ececec',
      secondary: BACKGROUND_SECONDARY,
      border: BORDER,
      hover: HOVER,
      gradientEffectStart: GRADIENT_EFFECT_START,
      gradientEffectEnd: GRADIENT_EFFECT_END,
      gradientEffectStartDesktop: GRADIENT_EFFECT_START_DESKTOP,
      gradientEffectEndDesktop: GRADIENT_EFFECT_END_DESKTOP,
      backgroundDisabled: BACKGROUND_DISABLED,
      backgroundBlack: '#1b1b1b',
    },

    text: {
      title: PRIMARY_MAIN,
      subTitle: TEXT_SUBTITLE,
      paragraph: TEXT_PRIMARY,
      paragraphSecondary: TEXT_SECONDARY,
      hyperlink: '#6161ff',
    },
  },
});

const material: PaletteOptions = {
  type: 'light',
  text: {
    primary: TEXT_PRIMARY,
    secondary: TEXT_SECONDARY,
    disabled: DISABLED,
  },
  primary,
  background: {
    paper: BACKGROUND_PRIMARY,
    default: BACKGROUND_SECONDARY,
  },
  divider: BORDER,
  action: {
    hover: HOVER,
  },
};

export default {
  styled,
  material,
};
