import styled, { css } from 'styled-components';

import { COLOR_30_PERCENT } from '../../styles/themes';

export type ProfilePositionType = 'side' | 'line';

type Props = {
  position: ProfilePositionType;
};

export const Container = styled.section<Props>`
  padding: 0% !important;
  display: flex !important;

  hr {
    margin: 0% !important;
  }

  #Profile-avatar {
    align-self: center;
    border: 15px solid ${({ theme }) => theme.colors.primary.light.concat(COLOR_30_PERCENT)};
    & > img {
      border: 5px solid ${({ theme }) => theme.colors.primary.main};
      border-radius: 50%;
    }

    img {
      background-color: ${({ theme }) => theme.colors.background.primaryStrong};
    }
  }

  #Profile-avatar {
    background-color: ${({ position, theme }) =>
      theme.title === 'light' && position === 'side' ? theme.colors.background.backgroundBlack : 'none'};
  }

  ${(props) => (props.position === 'side' ? sideProfile : rowProfile)};
`;

const rowProfile = css`
  #Profile-avatar {
    width: 175px;
    height: 175px;
    margin-left: -88px;
  }

  #Profile-info {
    padding: 4% 5%;
    padding-left: 2%;

    #Profile-particulars-intro > p {
      padding-bottom: 2%;
    }

    .Profile-particulars-info-topics {
      margin-bottom: -2%;
    }
  }

  #Profile-particulars {
    padding-left: 4%;
  }
`;

const sideProfile = css`
  font-size: 0.9rem;
  flex-direction: column;
  width: min-content;

  #Profile-avatar {
    margin-left: 0%;
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  #Profile-info {
    margin-top: 2vh;
    margin-bottom: 0;
    padding: 0;
    justify-content: center;
  }

  .Global-icon-svg {
    font-size: 0.81rem;
  }

  #Profile-particulars {
    display: flex;
    justify-content: center;
  }

  #Profile-last-Divider {
    margin-bottom: 1em !important;
  }
`;

export const Info = styled.section`
  display: grid;
  grid-gap: 0.8em;

  width: 100%;
  padding: 5%;
`;
