import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Dispatch, bindActionCreators } from 'redux';

import { AppTranslations } from '../../../assets/translations';
import { LoadingSpin, Error } from '../../../components';
import { Project, ProjectDTO as ProjectIntroDTO, PageLanguage, DisplayType, BaseProject, MemberDTO } from '../../../constants/types';
import Api from '../../../services/api';
import { ApplicationState } from '../../../store';
import { returnPreviousRoute } from '../../../store/ducks/menu';
import DisplayUtils from '../../../Utils/DisplayUtils';

import ProjectView from './ProjectView';

type StateProps = {
  projects: Array<ProjectIntroDTO>;
  pageLanguage: PageLanguage;
  displayType: DisplayType;
  hasHistory: boolean;
};

type DispatchProps = {
  returnPreviousRoute: () => void;
};

type OwnProps = {
  projectId: string;
};

type Props = StateProps & OwnProps & DispatchProps;

const ProjectDatailsPage: React.FC<Props> = ({ pageLanguage, projectId, displayType, projects, hasHistory, returnPreviousRoute }) => {
  const [state, setState] = useState(initialState());

  const isMobileDevice = DisplayUtils.isMobileDevice(displayType);

  const loadRequestProject = () => {
    const projectSelected = (state.project && state.project[pageLanguage]) || undefined;

    if (projectSelected && projectSelected.id === projectId) {
      setState((prev) => ({ ...prev, hasError: false, loading: false, projectSelected: projectSelected }));
    } else {
      getProject(projectId, pageLanguage, state, setState);
    }
  };

  useEffect(loadRequestProject, [pageLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const project = state.project && state.project[pageLanguage] ? state.project[pageLanguage] : undefined;

  if (!project && !state.hasError && !state.loading) loadRequestProject();
  if (state.hasError) {
    const project = projects.find((p) => p.id === projectId);
    const message = AppTranslations[pageLanguage].info.TEXT_ERROR_FIND_PROJECT;
    return (
      <Error
        style={{ top: '0', marginTop: '25%' }}
        message={message}
        href={project && project.refLink}
        onClickTryAgain={loadRequestProject}
      />
    );
  } else if (state.loading || !project) return <LoadingSpin />;

  return (
    <ProjectView
      project={project}
      pageLanguage={pageLanguage}
      isMobileDevice={isMobileDevice}
      hasHistory={hasHistory}
      turnBackButton={returnPreviousRoute}
    />
  );
};

type State = {
  projectSelected?: Project;
  project?: {
    [language: string]: Project | undefined;
  };
  loading: boolean;
  hasError: boolean;
};

interface ProjectDTO extends BaseProject {
  projectStart: string;
  projectEnd: string;
  squad: Array<MemberDTO>;
}

const initialState = (): State => ({ loading: false, hasError: false });

const dtoToProject = (dto: ProjectDTO): Project => ({
  ...dto,
  projectStart: new Date(dto.projectStart),
  projectEnd: new Date(dto.projectEnd),
  squad: dto.squad.map((v) => ({
    ...v,
    workStart: new Date(v.workStart),
    workEnd: new Date(v.workEnd),
  })),
});

const getProject = (projectId: string, pageLanguage: PageLanguage, state: State, setState: React.Dispatch<React.SetStateAction<State>>) => {
  if (!state.loading || state.hasError) setState((prev) => ({ ...prev, hasError: false, loading: true }));

  Api.getProject<ProjectDTO>(pageLanguage, projectId)
    .then((project) =>
      setState((prev) => ({ hasError: false, loading: false, project: { ...prev.project, [pageLanguage]: dtoToProject(project) } }))
    )
    .catch(() => setState((prev) => ({ ...prev, hasError: true, loading: false })));
};

const mapStateToProps = (state: ApplicationState) => ({
  projects: state.projects.data[state.pageLanguage][state.workplace],
  pageLanguage: state.pageLanguage,
  displayType: state.displaySize.type,
  hasHistory: state.menu.hasHistory,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ returnPreviousRoute }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDatailsPage);
