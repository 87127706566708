import React from 'react';
import { connect } from 'react-redux';

import { Dispatch, bindActionCreators } from 'redux';

import { Error } from '..';

import { PageLanguage, Language } from '../../constants/types';
import { ApplicationState } from '../../store';
import { loadRequest } from '../../store/ducks/languages/actions';
import StoreUtils from '../../Utils/StoreUtils';
import LoadingLanguages from '../Loading/LoadingListRow';

type StateProps = {
  languagesError: boolean;
  languagesData: Array<Language>;
  languagesLoading: boolean;
  pageLanguage: PageLanguage;
};

type DispatchProps = {
  loadRequest(pageLanguage: PageLanguage): void;
};

type OwnProps = {
  children?: never;
  classNameForComponentView?: string;
  ComponentView: React.FC<{ languages: Array<Language>; pageLanguage: PageLanguage; className?: string }>;
};

type Props = StateProps & DispatchProps & OwnProps;

const LanguageContainer: React.FC<Props> = (props) => {
  const { languagesError, languagesData, languagesLoading, ComponentView } = props;
  const languagesIsEmpty = StoreUtils.StateIsEmpty({ data: languagesData, loading: languagesLoading, error: languagesError });
  const languages = languagesIsEmpty ? [] : languagesData;

  const loadLanguages = () => props.loadRequest(props.pageLanguage);

  if (languagesIsEmpty) loadLanguages();

  if (languagesError) return <Error position={1} onClickTryAgain={loadLanguages} />;
  else if (languagesLoading) return <LoadingLanguages />;

  return <ComponentView className={props.classNameForComponentView} languages={languages} pageLanguage={props.pageLanguage} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  languagesError: state.languages.error,
  languagesData: state.languages.data[state.pageLanguage],
  languagesLoading: state.languages.loading,
  pageLanguage: state.pageLanguage,
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageContainer);
