import React from "react";

import { Error } from "../../../../components";
import ListUtils from "../../../../Utils/ListUtils";
import StringUtils from "../../../../Utils/StringUtils";

type Props = {
  children?: never;
  title: string;
  message: string;
  filterOfTechs: Array<string>;
  filterOfCompany: string;
  filterOfYear: string;
  totalProjects: number;
};

const NotFoundProjectMessage: React.FC<Props> = (props) => {
  const group = (msg: string) => `- ${msg}`;

  const techFilterMessage = ListUtils.isNotNullOrEmpty(props.filterOfTechs) ? group(props.filterOfTechs.join(", ")) : "";
  const companyFilterMessage = StringUtils.isNotNullOrEmpty(props.filterOfCompany) ? group(props.filterOfCompany) : "";
  const yearFilterMessage = StringUtils.isNotNullOrEmpty(props.filterOfYear) ? group(props.filterOfYear) : "";

  const filterMessage = [techFilterMessage, companyFilterMessage, yearFilterMessage].filter((e) => e !== "").join("\n");

  return (
    <Error
      title={props.title}
      message={props.message.replace("#FILTER#", filterMessage).replace("#PRJ#", `( ${props.totalProjects.toString()} )`)}
    />
  );
};

export default NotFoundProjectMessage;
