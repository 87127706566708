import React, { useState } from "react";
import { connect } from "react-redux";

import { Dispatch, bindActionCreators } from "redux";

import { PageLanguage, RouteType, Skill, Workplace } from "../../constants/types";
import { ApplicationState } from "../../store";
import { loadRequest } from "../../store/ducks/skills/actions";
import { setTimeoutOnClick as setTime } from "../../Utils";
import RouterUtils from "../../Utils/RouterUtils";
import StoreUtils from "../../Utils/StoreUtils";

import LoadingDashboard from "./components/Loading";
import DashboardView from "./DashboardView";

import { Error, LoadingSpin } from "..";

type StateProps = {
  skillsData: Array<Skill>;
  skillsError: boolean;
  skillsLoading: boolean;
  workplace: Workplace;
  pageLanguage: PageLanguage;
};

type DispatchProps = {
  loadRequest(pageLanguage: PageLanguage, workplace: Workplace): void;
};

type OwnProps = {
  navigate: (route: RouteType) => void;
  circularLoad?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  option: number;
  openHowItsWork: boolean;
};

const initialState = (): State => ({
  option: 0,
  openHowItsWork: false,
});

const SkillsDashboard: React.FC<Props> = (props) => {
  const [state, setState] = useState(initialState());

  const stateIsEmpty = StoreUtils.StateIsEmpty({ data: props.skillsData, error: props.skillsError, loading: props.skillsLoading });

  const handleMenuOption = (option: number) => setState((prev) => ({ ...prev, option }));
  const handleHowItsWork = () => setState((prev) => ({ ...prev, openHowItsWork: !prev.openHowItsWork }));
  const navigateProjectsByTechnology = (filter: string) =>
    setTime(() => props.navigate(RouterUtils.getRouteProjectsWithTechFilter(filter)));

  if (stateIsEmpty) props.loadRequest(props.pageLanguage, props.workplace);
  if (props.skillsError) return <Error onClickTryAgain={() => props.loadRequest(props.pageLanguage, props.workplace)} />;
  else if (props.skillsLoading || stateIsEmpty)
    return props.circularLoad ? <LoadingSpin style={{ marginTop: "5%" }} /> : <LoadingDashboard />;

  return (
    <DashboardView
      currentOption={state.option}
      handleMenuOption={handleMenuOption}
      handleDialogHowItsWork={handleHowItsWork}
      openHowItsWork={state.openHowItsWork}
      onClickElementPanel={navigateProjectsByTechnology}
      pageLanguage={props.pageLanguage}
      skills={props.skillsData}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  skillsData: state.skills.data[state.workplace],
  skillsError: state.skills.error,
  skillsLoading: state.skills.loading,
  workplace: state.workplace,
  pageLanguage: state.pageLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SkillsDashboard);
