import { PageContent } from "../../constants/types.js";

type AppTranslationType = {
  [pageLanguage: string]: {
    info: {
      TEXT_COMPANY: string;
      TEXT_TITLE_INFO_ERRO: string;
      TEXT_INFO_ERRO: string;
      TEXT_NOT_FOUND_FILTER: string;
      TEXT_DEFAULT_NOT_FOUND: string;
      TEXT_ERROR_FIND_PROJECT: string;
      TEXT_INFO_SKILL_DASHBORD: string;
      TEXT_INFO_SKILL_DASHBORD_OTHERS: string;
    };
    links: {
      TEXT_MORE_DETAILS: string;
      TEXT_TRY_AGAIN: string;
      TEXT_HOW_WORKS: string;
      TEXT_SHOW_LESS: string;
      TEXT_SHOW_MORE: string;
      TEXT_BUTTON_BACK: string;
      TEXT_BUTTON_NEXT: string;
      TEXT_BUTTON_CLOSE: string;
      TEXT_NEW_TAB: string;
      TEXT_BUTTON_TURN_BACK: string;
    };
    menu: {
      TEXT_HOME: string;
      TEXT_ABOUT_ME: string;
      TEXT_WORK_EXPERIENCE: string;
      TEXT_JOBS: string;
      TEXT_PROJECTS: string;
      TEXT_SKILLS: string;
      TEXT_EDUCATION: string;
      TEXT_COLLEGES: string;
      TEXT_COURSES: string;
      TEXT_LANGUAGES: string;
      TEXT_RECOGNITIONS: string;
      TEXT_BLOGS: string;
    };
    components: {
      workplace: {
        TEXT_COMPANIES: string;
        TEXT_SELECTED: string;
      };
      jobs: {
        TEXT_PREFIX_COMPANY: string;
        TEXT_BUTTON_PREVIOUS_JOB: string;
        TEXT_BUTTON_NEXT_JOB: string;
        PROJECTS: string;
      };
      options: {
        TEXT_TOTAL: string;
        TEXT_FOUND: string;
        TEXT_FILTER_BY_TECHNOLOGIES: string;
        TEXT_FILTER_BY_CATEGORY: {
          github: string;
          jobs: string;
          both: string;
        };
        TEXT_FILTER_BY_YEAR: string;
        TEXT_SELECT_ALL_OPTIONS: string;
        TEXT_SELECT_ORDER_BY: string;
      };
      ranking: {
        TEXT_RANKING: string;
        TEXT_RANKING_PROJECTS: string;
        selectButton: {
          options: {
            RECENTLY: string;
            STARS: string;
            PROJECTS: string;
          };
        };
      };
      skillDashboard: {
        HEADER: {
          [key: string]: string;
        };
        TOOLTIP_TECHNOLOGY_BAR: string;
        DIALOG_HOW_ITS_WORKS: {
          TEXT_TITLE: string;
          content: Array<PageContent>;
        };
      };
      projects: {
        companyLabel: string;
        selectOrderbyButton: {
          options: {
            RELEVANCE: string;
            RECENT: string;
            OLDER: string;
          };
        };
      };
    };
  };
};

type PagesTranslationType = {
  [pageLanguage: string]: {
    HOME: {
      banner: {
        TEXT_VAR: string;
        TEXT_FUNCTION: string;
        desktop: {
          TEXT_FIRST_ATRIBUTE: string;
          TEXT_FIRST_ATRIBUTE_VALUE: string;
          TEXT_SECOND_ATRIBUTE: string;
          TEXT_SECOND_ATRIBUTE_VALUE: string;
        };
        mobileDevice: {
          TEXT_FIRST_ATRIBUTE: string;
          TEXT_FIRST_ATRIBUTE_VALUE: string;
          TEXT_SECOND_ATRIBUTE: string;
          TEXT_SECOND_ATRIBUTE_VALUE: string;
        };
      };
      highlightsAndLanguages: {
        TEXT_TITLE_HIGHLIGHTS: string;
        TEXT_TITLE_LANGUAGES: string;
      };
      education: {
        TEXT_TITLE: string;
      };
      othersCourses: {
        TEXT_COURSE_TITLE: string;
      };
      profile: {
        TEXT_AGE: string;
        TEXT_BUTTON_MY_HISTORY: string;
        TEXT_INTRO: string;
      };
      skills: {
        TEXT_TITLE: string;
      };
      workExperience: {
        TEXT_TITLE: string;
      };
    };
    ABOUT_ME: { content: Array<PageContent> };
    EDUCATION: {
      TEXT_CERTIFICATE: string;
      TEXT_TITLE_LANGUAGES: string;
      TEXT_TITLE_COLLEGE: string;
      TEXT_TITLE_SPECIALIZATION: string;
      TEXT_TITLE_RECOGNITIONS: string;
      LANGUAGES: {
        content: Array<PageContent>;
      };
      COLLEGE: {
        content: Array<PageContent>;
      };
      SPECIALIZATION: {
        content: Array<PageContent>;
      };
    };
    SKILLS: {
      INTRO: { content: Array<PageContent> };
      RAKING: { content: Array<PageContent> };
    };
    PROJECT_DETAILS: {
      PROJECT_TIME: string;
      ARCHITECTURE_DESIGN_PATERNS: string;
      SQUAD: string;
      STACK: string;
      OTHERS: string;
      TEXT_NOT_FOUND: string;
      PROJECT_POSITION: string;
      TEXT_PRIVATE_PROJECT: string;
    };
    JOBS: {
      TEXT_TITLE: string;
      TEXT_INTRO: { content: Array<PageContent> };
    };
    BLOG: {
      ARTICLE: string;
      TALK: string;
      EVENT: string;
    };
    PROJECTS: {
      TEXT_TITLE: string;
      DIALOG_HOW_ITS_WORKS: {
        TEXT_TITLE: string;
        content: Array<PageContent>;
      };
      introduction: {
        INTRO: Array<PageContent>;
        COMPANY_LABEL: string;
        COMPANY_INFO: string;
        GITHUB_LABEL: string;
        GITHUB_INFO: string;
      };
    };
  };
};

export const AppTranslations: AppTranslationType = {
  EN: require("./app/en.json"),
  PT: require("./app/pt.json"),
  ES: require("./app/es.json"),
  FR: require("./app/fr.json"),
};

export const PagesTranslations: PagesTranslationType = {
  EN: require("./pages/en.json"),
  PT: require("./pages/pt.json"),
  ES: require("./pages/es.json"),
  FR: require("./pages/fr.json"),
};
