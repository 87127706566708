import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import { Error } from '..';

import { College, PageLanguage } from '../../constants/types';
import { ApplicationState } from '../../store';
import { loadRequest as loadColleges } from '../../store/ducks/colleges/actions';
import StoreUtils from '../../Utils/StoreUtils';
import Loading from '../Loading/LoadingListRow';

type StateProps = {
  collegesloading: boolean;
  collegesError: boolean;
  collegesData: Array<College>;
  pageLanguage: PageLanguage;
};

type DispatchProps = {
  loadColleges(pageLanguage: PageLanguage): void;
};

type OwnProps = {
  children?: never;
  ComponentView: React.FC<{ colleges: Array<College>; pageLanguage: PageLanguage; className?: string }>;
};

type Props = StateProps & DispatchProps & OwnProps;

const CollegesContainer: React.FC<Props> = (props) => {
  const { collegesError, collegesData, collegesloading, ComponentView } = props;

  const collegesIsEmpty = StoreUtils.StateIsEmpty({ data: collegesData, loading: collegesloading, error: collegesError });
  const colleges = collegesIsEmpty ? [] : collegesData.sort((a,b) => b.courseEnd.getFullYear() - a.courseEnd.getFullYear());

  const loadColleges = () => props.loadColleges(props.pageLanguage);

  if (collegesIsEmpty) loadColleges();

  if (collegesError) return <Error onClickTryAgain={loadColleges} />;
  else if (collegesloading || collegesIsEmpty) return <Loading />;

  return <ComponentView className="College-view" colleges={colleges} pageLanguage={props.pageLanguage} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  collegesError: state.colleges.error,
  collegesData: state.colleges.data[state.pageLanguage],
  collegesloading: state.colleges.loading,
  pageLanguage: state.pageLanguage,
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadColleges }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CollegesContainer);
