import React from 'react';
import { connect } from 'react-redux';

import { Language, SocialMedia } from '..';

import { useLocation, useHistory } from 'react-router-dom';

import { Dispatch, bindActionCreators } from 'redux';

import { ROUTES } from '../../constants/routesConstants';
import { DisplayType, RouteType } from '../../constants/types';
import { ApplicationState } from '../../store';
import { navigate } from '../../store/ducks/menu';
import DisplayUtils from '../../Utils/DisplayUtils';
import ThemeButton from '../Buttons/ThemeButton';
import DrawerMenu from '../Navigation/DrawerMenu';

import { Container } from './styles';

type DispatchProps = {
  navigate: (route: RouteType) => void;
};

type OwnProps = {
  displayType: DisplayType;
};

const Header: React.FC<OwnProps & DispatchProps> = (props) => {
  let location = useLocation(); // eslint-disable-line prefer-const
  const history = useHistory();

  const isMobilePhone = DisplayUtils.isMobilePhoneSM(props.displayType);
  const isMobilePhoneMedium = DisplayUtils.isMobilePhone(props.displayType);
  const isHomePage = location.pathname === ROUTES.HOME.path;

  const navigateMenu = (route: RouteType) => props.navigate(route);
  const navigateFromHome = (route: RouteType) => history.push(route.path);

  return (
    <Container id="Header-container" isHomePage={isHomePage}>
      {isMobilePhone && <DrawerMenu idCss="Header-menu" navigate={isHomePage ? navigateFromHome : navigateMenu} />}
      {!isMobilePhoneMedium && <SocialMedia id="Header-social-media" />}

      <ThemeButton id="Header-theme" />

      <Language id="Header-language" />
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ navigate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
