import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { bindActionCreators, Dispatch } from 'redux';

import { PageLanguage, WorkExperience, DisplayType, RouteType } from '../../constants/types';
import { ApplicationState } from '../../store';
import { loadRequest } from '../../store/ducks/jobs/actions';

import { Error, LoadingSpin } from '..';

import { navigate } from '../../store/ducks/menu';
import { setTimeoutOnClick } from '../../Utils';
import DisplayUtils from '../../Utils/DisplayUtils';
import RouterUtils from '../../Utils/RouterUtils';
import StoreUtils from '../../Utils/StoreUtils';

import LoadingSkelleton from './components/Loading';
import { isNewStateFromHomePage, openJobFromHomePage as openJob, getJobs, moveToScrollJobID } from './helpers';
import JobsView from './Jobs';
import { ClickJob as ClickJobType } from './types';

type StateToProps = {
  jobsData: Array<WorkExperience>;
  jobsLoading: boolean;
  jobsError: boolean;
  pageLanguage: PageLanguage;
  displayType: DisplayType;
};

type OwnProps = {
  children?: never;
  callbackJobsLength?: (jobsLength: number) => void;
  homepage?: boolean;
  commonPage?: boolean;
  showJobId?: string;
  viewSomeProjects?: number;
};

type DispatchProps = {
  loadRequest: (pageLanguage: PageLanguage) => void;
  navigate: (route: RouteType) => void;
};

type Props = OwnProps & StateToProps & DispatchProps;

const Jobs: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const { displayType, pageLanguage, homepage, showJobId = '', viewSomeProjects, jobsData, jobsLoading, jobsError } = props;

  const [currentJob, setOpenJob] = useState(0);
  const [jobIdSelected, setJobIdSelected] = useState(showJobId);
  const [expandContent, setExpandContent] = useState(false);

  const jobsIsEmpty = StoreUtils.StateIsEmpty({ data: jobsData, loading: jobsLoading, error: jobsError });
  const jobs = jobsIsEmpty ? [] : getJobs(jobsData, viewSomeProjects);

  const isMobilePhone = DisplayUtils.isMobilePhone(displayType);

  const loadRequest = () => props.loadRequest(pageLanguage);
  const handleExpandContent = () => setExpandContent(!expandContent);
  const handleClickJob = (jobId: string, JobIndex: number) => clickJob(jobId, JobIndex, currentJob, setOpenJob, history, homepage);
  const callbackWithLength = () => callback(jobsIsEmpty, jobsData.length, props.callbackJobsLength);

  useEffect(() => moveToScrollJobID(currentJob, expandContent, displayType), [currentJob, expandContent, displayType]);
  useEffect(callbackWithLength, [jobs]); // eslint-disable-line react-hooks/exhaustive-deps

  if (jobsIsEmpty) loadRequest();
  if (!jobsIsEmpty && isNewStateFromHomePage(jobIdSelected, currentJob)) openJob(jobsData, jobIdSelected, setOpenJob, setJobIdSelected);

  if (jobsError) return <Error onClickTryAgain={loadRequest} />;
  else if (jobsLoading || jobsIsEmpty) return props.commonPage ? <LoadingSpin style={{ marginTop: '5%' }} /> : <LoadingSkelleton />;

  return (
    <JobsView
      currentJob={currentJob}
      expandContent={expandContent}
      handleClickJob={handleClickJob}
      handleExpandContent={handleExpandContent}
      isMobilePhone={isMobilePhone}
      jobs={jobs}
      pageLanguage={pageLanguage}
      homepage={homepage}
      toggleJob={handleClickJob}
    />
  );
};

const clickJob: ClickJobType = (jobId, JobIndex, currentJob, setOpenJob, history, homepage) => {
  if (homepage) {
    const navigateToJob = (route: RouteType) => {
      navigate(route);
      history.push(route.path);
    };
    setTimeoutOnClick(() => navigateToJob(RouterUtils.getRouteJobId(jobId)));
  } else JobIndex !== currentJob && setOpenJob(JobIndex);
};

const callback = (jobsIsEmpty: boolean, jobsLength: number, callbackJobsLength?: (jobsLength: number) => void) => {
  if (callbackJobsLength && !jobsIsEmpty) callbackJobsLength(jobsLength);
};

const mapStateToProps = (state: ApplicationState) => ({
  jobsData: state.jobs.data[state.pageLanguage],
  jobsLoading: state.jobs.loading,
  jobsError: state.jobs.error,
  pageLanguage: state.pageLanguage,
  displayType: state.displaySize.type,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest, navigate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
