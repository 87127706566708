import { AppTranslations } from "../../assets/translations";
import { RankingSkills, PageLanguage } from "../../constants/types";
import ObjectUtils from "../../Utils/ObjectUtils";

import { OrderRankingClassificationByType, OptionStateType, StateTypeOptionEnum, StateType } from "./types";

export const updateStateWithTheNewRankingData = (ranking: RankingSkills, setState: React.Dispatch<React.SetStateAction<StateType>>) => {
  setState((prev) => ({ ...prev, ranking, rankingOrderByDefault: ObjectUtils.clone(ranking) }));
};

/**
 * Function to sort the ranking by the type informed and set new state with the new ranking and the current option selected
 *
 * @param {"stars" | "projects"} type
 * @param {StateTypeOptionEnum} optionSelected
 * @param {RankingSkills} ranking
 * @param {React.Dispatch<React.SetStateAction<StateType>>} setState
 */
export const orderRankingClassificationBy: OrderRankingClassificationByType = (type, optionSelected, ranking, setState) => {
  ranking.classification.sort((a, b) => b[type] - a[type]);

  setState((prev) => ({
    ...prev,
    ranking,
    options: {
      ...prev.options,
      selected: prev.options.values.find((e) => e.key === optionSelected) || prev.options.selected,
    },
  }));
};

/**
 * Function to set new state from initial state and with the current option selected.
 * This function is useful when the workplace is changed which able the state to keep the current option selected
 *
 * @param {StateType} newState
 * @param {React.Dispatch<React.SetStateAction<StateType>>} setState
 */
export const updateStateToInitialStateWithTheCurrentSelectedOption = (
  newState: StateType,
  setState: React.Dispatch<React.SetStateAction<StateType>>
) => {
  setState((prev) => ({ ...newState, options: { ...newState.options, selected: prev.options.selected } }));
};

/**
 * Function to set new state with the current option selected in the filter.
 * This function is useful when the page language is changed which able the state to keep the current option selected
 *
 * @param {PageLanguage} pageLanguage
 * @param {React.Dispatch<React.SetStateAction<StateType>>} setState
 */
export const updateStateWithCurrentOptionSelectedByLanguage = (
  pageLanguage: PageLanguage,
  setState: React.Dispatch<React.SetStateAction<StateType>>
) => {
  setState((prev) => {
    const values = [...prev.options.values].map((value) => ({
      ...value,
      label: {
        ...value.label,
        [pageLanguage]: AppTranslations[pageLanguage].components.ranking.selectButton.options[value.key],
      },
    }));

    const optionSelectedByNewLanguage = values.find((v) => v.key === prev.options.selected.key) || prev.options.selected;

    return {
      ...prev,
      options: {
        ...prev.options,
        selected: optionSelectedByNewLanguage,
        values,
      },
    };
  });
};

/**
 * Function to build options with labels by the page language selected
 *
 * @param {PageLanguage} pageLanguage
 * @returns {Array<OptionStateType>}
 */
export const buildOptionsBy = (pageLanguage: PageLanguage): Array<OptionStateType> => {
  return [
    {
      key: StateTypeOptionEnum.PROJECTS,
      label: {
        [pageLanguage]: AppTranslations[pageLanguage].components.ranking.selectButton.options.PROJECTS,
      },
    },
    {
      key: StateTypeOptionEnum.STARS,
      label: {
        [pageLanguage]: AppTranslations[pageLanguage].components.ranking.selectButton.options.STARS,
      },
    },
    {
      key: StateTypeOptionEnum.RECENTLY,
      label: {
        [pageLanguage]: AppTranslations[pageLanguage].components.ranking.selectButton.options.RECENTLY,
      },
    },
  ];
};
