import React, { Fragment } from "react";
import { connect } from "react-redux";

import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";

import { AppTranslations, PagesTranslations } from "../../../assets/translations";
import { PrintPageContent, Ranking, SkillsDashboard, ToggleWorkspace } from "../../../components";
import DialogHowItsWork from "../../../components/Dialog/HowItsWorkDialog";
import { PageLanguage, RouteType } from "../../../constants/types";
import { navigate } from "../../../store/ducks/menu";

type DispatchProps = {
  navigate: (route: RouteType) => void;
};

type Props = {
  pageLanguage: PageLanguage;
};

const SkillsPage: React.FC<Props & DispatchProps> = ({ pageLanguage, navigate }) => {
  const { INTRO, RAKING } = PagesTranslations[pageLanguage].SKILLS;
  const { DIALOG_HOW_ITS_WORKS } = AppTranslations[pageLanguage].components.skillDashboard;

  return (
    <Fragment>
      <PrintPageContent pageContent={INTRO.content} />
      <br />
      <ToggleWorkspace />
      <br />
      <SkillsDashboard navigate={navigate} circularLoad />
      <br />
      <PrintPageContent pageContent={RAKING.content} />
      <br />
      <ToggleWorkspace />
      <br />
      <br />
      <DialogHowItsWork title={DIALOG_HOW_ITS_WORKS.TEXT_TITLE} content={DIALOG_HOW_ITS_WORKS.content} pageLanguage={pageLanguage} />
      <Section>
        <Ranking />
      </Section>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ navigate }, dispatch);

export default connect(null, mapDispatchToProps)(SkillsPage);

const Section = styled.section`
  margin-top: 20px;
  width: 100%;

  #Container-rating {
    flex-direction: row;
    font-size: 1rem !important;
    padding: 0 !important;

    li {
      max-width: min-content;
      white-space: pre-wrap;
      margin: 0 10%;
      margin-bottom: 30px;
    }
  }
`;
