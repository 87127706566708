import { AnyAction } from "redux";
import { put } from "redux-saga/effects";

import { RankingSkills, Workplace } from "../../../constants/types";
import Api from "../../../services/api";

import { loadSuccess, loadFailure } from "./actions";

export interface Payload extends AnyAction {
  payload: Workplace;
}

export function* loadRankingSkills(action: Payload) {
  try {
    let response: RankingSkills | null = null;

    if (action.payload === "github") {
      response = yield Api.getRankingGithub();
    } else response = yield Api.getRankingJobs();

    switch (action.payload) {
      case "both":
        response = yield Api.getRankingAll();
        break;

      case "jobs":
        response = yield Api.getRankingJobs();
        break;

      case "github":
        response = yield Api.getRankingGithub();
        break;

      default:
        break;
    }

    if (!response) throw new Error("Not found - empty");

    yield put(loadSuccess({ ranking: response, workspace: action.payload }));
  } catch (err) {
    yield put(loadFailure());
  }
}
