import React from 'react';
import { connect } from 'react-redux';

import { Dispatch, bindActionCreators } from 'redux';

import { Error } from '..';

import { PageLanguage, Spotlight } from '../../constants/types';
import { ApplicationState } from '../../store';
import { loadRequest } from '../../store/ducks/highlights/actions';
import StoreUtils from '../../Utils/StoreUtils';
import LoadingHighlights from '../Loading/LoadingListRow';

import HighlightsView from './HighlightsView';

type StateProps = {
  highlightsError: boolean;
  highlightsData: Array<Spotlight>;
  highlightsLoading: boolean;
  pageLanguage: PageLanguage;
};

type DispatchProps = {
  loadRequest(pageLanguage: PageLanguage): void;
};

type OwnProps = {
  idCss?: string;
  className?: string;
  isHomePage?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

const Highlights: React.FC<Props> = (props) => {
  const { highlightsError, highlightsData, highlightsLoading } = props;

  const highlightsIsEmpty = StoreUtils.StateIsEmpty({ data: highlightsData, loading: highlightsLoading, error: highlightsError });
  const highlights = highlightsIsEmpty ? [] : highlightsData;

  if (highlightsIsEmpty) props.loadRequest(props.pageLanguage);

  if (highlightsError) return <Error onClickTryAgain={() => props.loadRequest(props.pageLanguage)} />;
  else if (highlightsLoading) return <LoadingHighlights />;

  return <HighlightsView highlights={highlights} idCss={props.idCss} className={props.className} isHomePage={props.isHomePage} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  highlightsError: state.highlights.error,
  highlightsData: state.highlights.data[state.pageLanguage],
  highlightsLoading: state.highlights.loading,
  pageLanguage: state.pageLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Highlights);
