import React from 'react';

import styled from 'styled-components';

import { TransitionEffect } from '../../../../components';
import { RadioTopicIcon } from '../../../../components/Icons';
import { Date as TitleDate, Subtitle as TitleCourse, SubtitleSmall as SubTitleSchool } from '../../../../components/Typography';
import { College as CollegeType } from '../../../../constants/types';

type Props = {
  colleges: Array<CollegeType>;
};

const CollegesView: React.FC<Props> = ({ colleges }) => {
  return (
    <TransitionEffect>
      <ul className="Paper-section-content">
        {colleges.map((college, i) => (
          <Course className="College-item" key={i}>
            <span className="College-topic">
              <TitleDate className="College-date" size="small">
                {college.courseStart.getFullYear()} - {college.courseEnd.getFullYear()}
              </TitleDate>

              <RadioTopicIcon className="Course-circle-topic" />
            </span>

            <hgroup className="College-titles">
              <TitleCourse fontWeight="normal"> {college.courseName} </TitleCourse>
              <SubTitleSchool>{`${college.schoolName} - ${college.schoolLocation}`}</SubTitleSchool>
            </hgroup>
          </Course>
        ))}
      </ul>
    </TransitionEffect>
  );
};

export default CollegesView;

const Course = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5%;
  align-items: flex-start;

  .College-date {
    margin: 0;
    align-self: start;
    white-space: nowrap;
  }

  .Course-circle-topic {
    font-size: 1rem;
    fill: darkgray;
    margin: 0 1em;
  }

  .College-topic {
    display: flex;
    align-items: center;
  }
`;
