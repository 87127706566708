import React from "react";

import { AppTranslations } from "../../../assets/translations";
import { FilterBy, Pagination, OptionSelect } from "../../../components";
import { OptionsType } from "../../../components/Selects/OptionsSelect";
import { PageLanguage, DisplayType, Workplace } from "../../../constants/types";

type Props = {
  children?: never;
  disabled: boolean;
  displayType: DisplayType;
  forceFilterTech?: boolean;
  handleClickPagination(page: number): void;
  handleFilterProjectsByCompany(filter?: string): void;
  handleFilterProjectsByTech(filter: string, finishLoadingSearch: () => void): void;
  handleFilterProjectsByYear(filter?: string): void;
  handleSelectOrderBy: (value?: string) => void;
  idCssHeader: string;
  isFilterActive: boolean;
  optionsToCategories: OptionsType;
  optionsToYears: OptionsType;
  page: number;
  pageLanguage: PageLanguage;
  projectsIsEmpty: boolean;
  projectsPerPage: number;
  selectedCompanyFilter: string;
  selectOderByOption?: string;
  selectedTechFilterInputText?: string;
  selectedYearFilter: string;
  totalProjects: number;
  totalProjectsPagination: number;
  workplace: Workplace;
};

const ProjectsSearch: React.FC<Props> = (props) => {
  const {
    displayType,
    idCssHeader,
    isFilterActive,
    page,
    projectsIsEmpty,
    projectsPerPage,
    totalProjects,
    totalProjectsPagination,
  } = props;

  const {
    TEXT_FILTER_BY_YEAR,
    TEXT_FILTER_BY_TECHNOLOGIES,
    TEXT_FILTER_BY_CATEGORY,
    TEXT_TOTAL,
    TEXT_FOUND,
    TEXT_SELECT_ORDER_BY,
  } = AppTranslations[props.pageLanguage].components.options;

  const ORDER_BY_OPTIONS = AppTranslations[props.pageLanguage].components.projects.selectOrderbyButton;

  return (
    <section id={idCssHeader}>
      <FilterBy
        disabled={props.disabled}
        fetchFilter={props.handleFilterProjectsByTech}
        forceSearch={props.forceFilterTech}
        idCss="Projects-filter-tech"
        initialSearch={props.selectedTechFilterInputText}
        text={TEXT_FILTER_BY_TECHNOLOGIES}
      />

      <OptionSelect
        idCss="Projects-filter-year"
        label={TEXT_FILTER_BY_YEAR}
        value={props.selectedYearFilter}
        options={props.optionsToYears}
        handleSelect={props.handleFilterProjectsByYear}
        disabled={props.disabled}
      />

      <OptionSelect
        idCss="Projects-filter-company"
        label={TEXT_FILTER_BY_CATEGORY[props.workplace]}
        value={props.selectedCompanyFilter}
        options={props.optionsToCategories}
        handleSelect={props.handleFilterProjectsByCompany}
        disabled={props.disabled}
      />

      <label id="Projects-filter-info">
        <span style={{ marginRight: "10px" }}>{`${TEXT_TOTAL}: ${totalProjects}`}</span>
        {isFilterActive && <span className="Mui-selected">{`${TEXT_FOUND}: ${totalProjectsPagination}`}</span>}
      </label>

      <OptionSelect
        idCss="Projects-orderby-select"
        label={TEXT_SELECT_ORDER_BY}
        options={Object.values(ORDER_BY_OPTIONS.options).map((value) => ({ value }))}
        value={props.selectOderByOption}
        handleSelect={props.handleSelectOrderBy}
      />

      {displayType !== "sm" && displayType !== "smd" && !projectsIsEmpty && (
        <Pagination
          id="Projects-pagination"
          elementsPerPage={projectsPerPage}
          handleClick={props.handleClickPagination}
          page={page}
          totalElements={totalProjectsPagination}
        />
      )}
    </section>
  );
};

export default ProjectsSearch;
