import { css } from 'styled-components';

export const cssSkillsWidthMax600 = css`
  #Home-skills {
    padding: 0%;

    .Section-Skills {
      padding: 4% 5%;
    }
    .Section-Skills-divider {
      margin: 2.5% 5%;
    }

    & > header {
      padding-bottom: 0% !important;
    }
    .Section-Ranking {
      padding-top: 0% !important;
    }
  }

  #Skills-dashboard-header-stepps,
  .Button-how-its-work {
    margin-right: 5%;
  }

  #Skills-dashboard {
    padding: 0% 5%;
  }
`;
