import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';

import { RouteType } from '../../../constants/types';
import BrowserUtils from '../../../Utils/BrowserUtils';
import StringUtils from '../../../Utils/StringUtils';

export enum Types {
  NAVEGATION = '@menu/NAVEGATION',
  TURN_BACK = '@menu/TURN_BACK',
}

export type State = {
  route: RouteType;
  hasHistory: boolean;
  history: Array<RouteType>;
};

export interface Action extends AnyAction {
  payload: RouteType;
}

export const navigate = (newRoute: RouteType) => action(Types.NAVEGATION, newRoute);
export const returnPreviousRoute = () => action(Types.TURN_BACK);

const INITIAL_STATE: State = {
  route: {
    id: '',
    name: '',
    path: '',
  },
  hasHistory: false,
  history: [],
};

const MenuReducer: Reducer<State, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.NAVEGATION:
      return newStateToNavegation(state, action.payload);
    case Types.TURN_BACK:
      return previusState(state);
    default:
      return state;
  }
};

const newStateToNavegation = (currentState: State, newRoute: RouteType): State => {
  if (isEquals(currentState.route, newRoute)) {
    return currentState;
  }
  updateBrowserRouteView(newRoute);
  currentState.history.push(newRoute);
  return { route: newRoute, history: currentState.history, hasHistory: hasHistory(currentState.history) };
};

const previusState = (currentState: State): State => {
  if (currentState.history.length <= 1) {
    return INITIAL_STATE;
  }

  do {
    currentState.history.pop();
  } while (isInvalidRoute(getLastRoute(currentState.history)) && hasHistory(currentState.history));

  const lastRoute = getLastRoute(currentState.history);
  updateBrowserRouteView(lastRoute);
  return { route: lastRoute, history: currentState.history, hasHistory: hasHistory(currentState.history) };
};

const updateBrowserRouteView = (newRoute: RouteType) => {
  if (newRoute) {
    const { name = '', path } = newRoute;
    if (!StringUtils.isNullOrEmpty(path)) {
      BrowserUtils.replaceUrlBrowser(path, name);
    }
  }
};

const hasHistory = (history: Array<RouteType>): boolean => history.length > 1;
const getLastRoute = (history: Array<RouteType>): RouteType => history[history.length - 1];
const isInvalidRoute = (route: RouteType): boolean => StringUtils.isNullOrEmpty(route.id) || StringUtils.isNullOrEmpty(route.path);
const isEquals = (currentRoute: RouteType, newRoute: RouteType) => currentRoute.id === newRoute.id && currentRoute.path === newRoute.path;

export default MenuReducer;
