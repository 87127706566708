import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import { AppTranslations } from "../../../assets/translations";
import { LinkButton as ButtonText } from "../../../components";
import { TitleSmall as Title } from "../../../components/Typography";
import { PageLanguage, DisplayType } from "../../../constants/types";
import { ApplicationState } from "../../../store";
import DisplayUtils from "../../../Utils/DisplayUtils";

type StateProps = {
  pageLanguage: PageLanguage;
  displayType: DisplayType;
};

type Props = {
  className?: string;
  onClick?: () => void;
  noButton?: boolean;
  title: string;
  textButton?: string;
};

const HeaderTitle: React.FC<StateProps & Props> = ({ className = "", displayType, onClick, noButton, pageLanguage, title, textButton }) => {
  const { TEXT_MORE_DETAILS } = AppTranslations[pageLanguage].links;
  const isMobilePhoneSM = DisplayUtils.isMobilePhoneSM(displayType);

  return (
    <Header className={className}>
      <Title> {title} </Title>
      {!noButton && <ButtonText handleClick={onClick} position="end" text={textButton || TEXT_MORE_DETAILS} noText={isMobilePhoneSM} />}
    </Header>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
  pageLanguage: state.pageLanguage,
});

export default connect(mapStateToProps)(HeaderTitle);

const Header = styled.header`
  display: flex;
  align-items: center;
`;
