import React from 'react';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { TreeItemProps, TreeItem } from '@material-ui/lab';

import { Topic, SubTopic } from '../../../Typography';

import { useTreeItemStyles } from './styles';

type StyledTreeItemProps = TreeItemProps & {
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  expanded?: boolean;
};

const TreeMenuItem: React.FC<StyledTreeItemProps> = (props) => {
  const classes = useTreeItemStyles();
  const { expanded = false, labelText, labelIcon: LabelIcon, labelInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={`Global-icon-svg ${classes.labelRoot}`}>
          <LabelIcon />

          <Topic className={`Expanded-${expanded} Menu-nav-item-text`} inheritColor>
            {labelText}
          </Topic>
          {labelInfo && <SubTopic className="Menu-nav-item-text-info">{labelInfo}</SubTopic>}
        </div>
      }
      classes={{
        content: classes.content,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

export default TreeMenuItem;
