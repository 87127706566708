import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';

import { Divider, TransitionEffect } from '..';

import { Profile, PageLanguage } from '../../constants/types';
import { Title as TitleTypography } from '../Typography';

import IMG_PROFILE from './../../assets/images/profile.jpg';
import Introduction from './components/Introduction';
import { Container, Info, ProfilePositionType } from './styles';

type Props = {
  ageNumber: string;
  className?: string;
  enableLastDivider?: boolean;
  idCss: string;
  isMobilePhoneSM: boolean;
  isSidePosition: boolean;
  navigateAboutMePage?: () => void;
  pageLanguage: PageLanguage;
  profile: Profile;
  positionView: ProfilePositionType;
};

export const ProfileView: React.FC<Props> = (props) => {
  const { profile, positionView = 'line', isSidePosition, isMobilePhoneSM, enableLastDivider, navigateAboutMePage } = props;
  const enableSmallSideProfile = positionView === 'side' && isMobilePhoneSM;

  const Title = () => (
    <TitleTypography id="Profile-title" fontWeight="normal">
      {profile.name}
    </TitleTypography>
  );

  const TitleStyleLine = () => (
    <Fragment>
      <Title />
      <Divider id="Profile-title-Divider" />
    </Fragment>
  );

  const SideBarMobileDevice = () => (
    <TransitionEffect>
      <figure id="Profile-sidebar-container">
        <Avatar
          id="Profile-avatar"
          alt="Image Profile"
          sizes='175'
          imgProps={{ width: '175', height: '175' }}
          src={IMG_PROFILE}
          variant="circle"
        />
        <TitleStyleLine />
      </figure>
    </TransitionEffect>
  );

  return (
    <Container id={props.idCss} className={props.className} position={positionView}>
      {enableSmallSideProfile ? (
        <SideBarMobileDevice />
      ) : (
        <TransitionEffect timeout={3500}>
          <Avatar
            component="figure"
            id="Profile-avatar"
            sizes='175'
            imgProps={{ width: '175', height: '175' }}
            alt="Image Profile"
            src={IMG_PROFILE}
            variant="circle"
          />
        </TransitionEffect>
      )}

      <TransitionEffect>
        <Info id="Profile-info">
          {!enableSmallSideProfile && <TitleStyleLine />}

          <Introduction
            ageNumber={props.ageNumber}
            isSidePosition={isSidePosition}
            navigate={navigateAboutMePage}
            profile={profile}
            pageLanguage={props.pageLanguage}
          />

          {enableLastDivider && <Divider id="Profile-last-Divider" />}
        </Info>
      </TransitionEffect>
    </Container>
  );
};

export default ProfileView;
