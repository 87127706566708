import React, { Fragment } from "react";

import styled from "styled-components";

import { PagesTranslations } from "../../../../assets/translations";
import { PrintPageContent } from "../../../../components";
import { GitHubIcon, BusinessIcon } from "../../../../components/Icons";
import { PageLanguage } from "../../../../constants/types";

type Props = {
  children?: never;
  pageLanguage: PageLanguage;
};

const Introduction: React.FC<Props> = (props) => {
  const { INTRO, COMPANY_LABEL, GITHUB_LABEL, COMPANY_INFO, GITHUB_INFO } = PagesTranslations[props.pageLanguage].PROJECTS.introduction;
  return (
    <Fragment>
      <PrintPageContent pageContent={INTRO} />
      <br />
      <ParagraphContainer style={{ marginTop: 0 }}>
        <LabelContainer>
          <BusinessIcon />
          {COMPANY_LABEL}
        </LabelContainer>
        {COMPANY_INFO}
      </ParagraphContainer>
      <ParagraphContainer style={{ marginBottom: 0 }}>
        <LabelContainer>
          <GitHubIcon />
          {GITHUB_LABEL}
        </LabelContainer>
        {GITHUB_INFO}
      </ParagraphContainer>
    </Fragment>
  );
};

export default Introduction;

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 5px;

  color: ${({ theme }) => theme.colors.text.subTitle};

  svg {
    width: 0.8em;
    margin: 0 5px;
  }
`;

const ParagraphContainer = styled.p`
  display: flex;
  align-items: start;
  font-size: 0.85rem;
  margin: 10px 0;
`;
