import { all, takeLatest } from 'redux-saga/effects';

import { loadBlog } from './blog/sagas';
import { Types as BlogTypes } from './blog/types';
import { loadColleges } from './colleges/sagas';
import { Types as CollegesTypes } from './colleges/types';
import { loadHighlights } from './highlights/sagas';
import { Types as HighlightsTypes } from './highlights/types';
import { loadJobs } from './jobs/sagas';
import { Types as JobsTypes } from './jobs/types';
import { loadLanguages } from './languages/sagas';
import { Types as LanguagesTypes } from './languages/types';
import { loadProfile } from './profile/sagas';
import { Types as ProfileTypes } from './profile/types';
import { loadProjects } from './projects/sagas';
import { Types as ProjectTypes } from './projects/types';
import { loadRankingSkills } from './ranking/sagas';
import { Types as RankingSkillsTypes } from './ranking/types';
import { loadSkills } from './skills/sagas';
import { Types as SkillsTypes } from './skills/types';

export default function* rootSaga() {
  return yield all([
    takeLatest(BlogTypes.LOAD_REQUEST, loadBlog),
    takeLatest(CollegesTypes.LOAD_REQUEST, loadColleges),
    takeLatest(HighlightsTypes.LOAD_REQUEST, loadHighlights),
    takeLatest(JobsTypes.LOAD_REQUEST, loadJobs),
    takeLatest(LanguagesTypes.LOAD_REQUEST, loadLanguages),
    takeLatest(ProfileTypes.LOAD_REQUEST, loadProfile),
    takeLatest(ProjectTypes.LOAD_REQUEST, loadProjects),
    takeLatest(RankingSkillsTypes.LOAD_REQUEST, loadRankingSkills),
    takeLatest(SkillsTypes.LOAD_REQUEST, loadSkills),
  ]);
}
