import React, { Fragment } from 'react';

import styled from 'styled-components';

import { SocialMedia, Divider } from '../../..';
import { MenuButton } from '../../../';
import { FollowMeIcon } from '../../../Icons';

type Props = {
  isDrawerOpen: boolean;
  onClickButton: () => void;
};

/* 
  <span>: is important about active styles for small devices.
*/

const FooterDrawer: React.FC<Props> = ({ onClickButton, isDrawerOpen }) => {
  return (
    <Fragment>
      <Divider id="Drawer-divider-footer" position="horizontal" />
      <Container id="Drawer-footer">
        <span>
          {!isDrawerOpen && <MenuButton id="Drawer-footer-button-socialmedia" onClick={onClickButton} icon={FollowMeIcon} text="" />}

          <SocialMedia
            id="Drawer-footer-social-media"
            inTransitionEffect={isDrawerOpen}
            timeStartTransitionEffect={2500}
            timeExitTransitionEffect={100}
          />
        </span>
      </Container>
    </Fragment>
  );
};

export default FooterDrawer;

export const Container = styled.footer`
  display: flex;
  width: 100%;
  span {
    width: 100%;
    display: flex;
  }
  #Drawer-footer-social-media {
    width: 100%;
  }
`;
