import { AppTranslations } from "../../../assets/translations";
import { BusinessIcon, GitHubIcon } from "../../../components/Icons";
import { OptionsType } from "../../../components/Selects/OptionsSelect";
import { PageLanguage, ProjectDTO } from "../../../constants/types";
import BrowserUtils from "../../../Utils/BrowserUtils";
import ListUtils from "../../../Utils/ListUtils";
import ObjectUtils from "../../../Utils/ObjectUtils";
import StringUtils from "../../../Utils/StringUtils";

import { BREAK_CHARS_REGEX, SPACE_CHAR_CODE, SUFFIXES_FOR_OPTIONS } from "./constants";
import {
  FilterProjectsByTechType,
  FilterProjectsByCompanyType,
  StateType,
  ClickPaginationType,
  FilterProjectsByYearType,
  InputProjectFiltersType,
  StateFiltersType,
  CompanyFiltersType,
} from "./types";

export const clickPagination: ClickPaginationType = (page, idCss, setState) => {
  setState((prev) => ({ ...prev, page: page }));
  BrowserUtils.scrollCenterId(idCss);
};

export const filterProjectsByTech: FilterProjectsByTechType = (projectsAll, setState, filter, finishLoadingSearch, clearSearch) => {
  const buildNewState = (prevState: StateType): StateType => {
    const filters = splitFilters(filter);
    const filteredProjects = getNewProjectsFiltered(projectsAll, prevState.selectedCompanyFilter, filters, prevState.selectedYearFilter);

    const newState: StateType = {
      ...prevState,
      page: 1,
      isLoadingFilters: false,
      forceFilterTech: false,
      filteredProjects,
      clearSearchFilterByTech: prevState.clearSearchFilterByTech || clearSearch,
    };

    if (filters.length > 0) {
      newState.selectedTechFilters = filters;
      newState.isActiveTechFilter = true;
    } else if (prevState.isActiveTechFilter) {
      newState.selectedTechFilters = [];
      newState.isActiveTechFilter = false;
    }

    if (finishLoadingSearch) setTimeout(() => finishLoadingSearch(), 300);
    return newState;
  };

  setState(buildNewState);
};

const CLEAN_FILTER = "";

export const filterProjectsByCompany: FilterProjectsByCompanyType = (projectsAll, setState, company, LABEL_ALL_OPTIONS) => {
  const buildNewState = (prevState: StateType): StateType => {
    const clearFilter = company === LABEL_ALL_OPTIONS;
    let filter = clearFilter ? CLEAN_FILTER : company;
    let filteredProjects = getNewProjectsFiltered(projectsAll, filter, prevState.selectedTechFilters, prevState.selectedYearFilter);

    if (ListUtils.isNullOrEmpty(filteredProjects)) {
      const notFoundJustByCompanyFilter =
        ListUtils.isNullOrEmpty(prevState.selectedTechFilters) && StringUtils.isNullOrEmpty(prevState.selectedYearFilter);

      if (notFoundJustByCompanyFilter) {
        filter = CLEAN_FILTER;
        filteredProjects = projectsAll;
      }
    } else if (!clearFilter) {
      // Filter might be no the full name because the method used is ".contains"
      // Because that, it is necessary to set the exactly company filter name which is showed at search component view
      filter = filteredProjects[0].company;
    }

    return {
      ...prevState,
      selectedCompanyFilter: filter,
      isLoadingFilters: false,
      page: 1,
      isActiveCompanyFilter: !clearFilter,
      filteredProjects,
    };
  };

  setState(buildNewState);
};

export const filterProjectsByYear: FilterProjectsByYearType = (projectsAll, setState, year, LABEL_ALL_OPTIONS) => {
  const buildNewState = (prevState: StateType): StateType => {
    const clearFilter = year === LABEL_ALL_OPTIONS;
    const filter = clearFilter ? CLEAN_FILTER : year;
    const filteredProjects = getNewProjectsFiltered(projectsAll, prevState.selectedCompanyFilter, prevState.selectedTechFilters, filter);
    return {
      ...prevState,
      selectedYearFilter: filter,
      isLoadingFilters: false,

      page: 1,
      isActiveYearFilter: !clearFilter,
      filteredProjects,
    };
  };
  setState(buildNewState);
};

export const getFilters = (LABEL_ALL_OPTIONS: string, projectsAll: Array<ProjectDTO>): StateFiltersType => {
  const dates: Array<number> = [];
  const categoryFilter: Array<CompanyFiltersType> = [{ name: LABEL_ALL_OPTIONS, origin: "none" }];

  projectsAll.forEach((p) => {
    if (p.projectEnd.getFullYear()) dates.push(p.projectEnd.getFullYear());
    if (p.projectStart.getFullYear()) dates.push(p.projectStart.getFullYear());

    if (!categoryFilter.find((e) => e.name === p.company)) categoryFilter.push({ name: p.company, origin: p.origin });
  });

  const yearFilters = ListUtils.getUniqueElements(dates.sort((a, b) => b - a).map((e) => e.toString()));

  return {
    company: categoryFilter,
    year: yearFilters.length > 0 ? [LABEL_ALL_OPTIONS].concat(yearFilters) : [],
  };
};

export const initialState = (pageLanguage: PageLanguage, filters?: Array<string>): StateType => {
  const defaultOderByOption = AppTranslations[pageLanguage].components.projects.selectOrderbyButton.options.RELEVANCE;

  const inputFilters = getInputProjectFilters(filters);

  const isActiveCompanyFilter = StringUtils.isNotNullOrEmpty(inputFilters.company);
  const isActiveYearFilter = StringUtils.isNotNullOrEmpty(inputFilters.year);
  const isActiveTechFilter =
    ListUtils.isNotNullOrEmpty(inputFilters.technologies) || StringUtils.isNotNullOrEmpty(inputFilters.initialTechFilter);

  return {
    // It is necessary for the first loading of the projects , where validates if projects must be filtered
    isLoadingFilters: isActiveTechFilter || isActiveCompanyFilter || isActiveYearFilter,

    selectedCompanyFilter: inputFilters.company,
    selectedTechFilters: inputFilters.technologies,
    selectedTechFilterUserInput: inputFilters.initialTechFilter,
    selectedYearFilter: inputFilters.year,
    selectedOderByOption: defaultOderByOption,

    isActiveTechFilter: false,
    isActiveCompanyFilter: false,
    isActiveYearFilter: false,

    changedWorkplace: 0,

    forceFilterTech: false,

    filteredProjects: [],
    page: 1,
    filters: {
      company: [],
      year: [],
    },
  };
};

const splitFilters = (filter: string) => ListUtils.getUniqueElements(filter.split(BREAK_CHARS_REGEX).filter((f) => f.length > 1));

const getNewProjectsFiltered = (allProjects: Array<ProjectDTO>, companyFilter: string, techFilters: Array<string>, yearFilter: string) => {
  let projects = ObjectUtils.clone(allProjects);

  if (StringUtils.isNotNullOrEmpty(companyFilter)) {
    projects = allProjects.filter((p) => p.company.toLowerCase().includes(companyFilter.toLowerCase()));
  }

  if (StringUtils.isNotNullOrEmpty(yearFilter)) {
    const yearsAreTheSame = (date: Date, year: string) => !!date && date.getFullYear().toString() === year;
    projects = projects.filter((p) => yearsAreTheSame(p.projectStart, yearFilter) || yearsAreTheSame(p.projectEnd, yearFilter));
  }

  if (techFilters.length > 0) {
    const getProjectsThatContainsTag = (p: ProjectDTO) => p.tags.find((tag) => StringUtils.containsAtList(tag, techFilters));
    projects = projects.filter(getProjectsThatContainsTag);
  }

  const oderByWeightOrDateDesc = (a: ProjectDTO, b: ProjectDTO) => {
    const weightDescOrder = (b.weight || 0) - (a.weight || 0);
    return weightDescOrder !== 0 ? weightDescOrder : b.projectEnd?.getTime() - a.projectEnd?.getTime();
  };

  return projects.sort(oderByWeightOrDateDesc);
};

export const getInputProjectFilters = (filters?: Array<string>): InputProjectFiltersType => {
  if (!filters) {
    return {
      company: "",
      year: "",
      technologies: [],
    };
  }
  const getQueryValue = (query?: string) => (query ? query.substring(query.indexOf("=") + 1, query.length) : "");

  const queryCompanyFilter = filters.find((f) => f.includes("company=") || f.includes("category="));
  const queryYearFilters = filters.find((f) => f.includes("year="));
  const queryTechFilters = filters.find((f) => f.includes("tech="));

  const companyFilter = getQueryValue(queryCompanyFilter);
  const yearFilter = getQueryValue(queryYearFilters);
  const techFilter = getQueryValue(queryTechFilters)?.split(SPACE_CHAR_CODE).join(" "); // prettier-ignore
  const techFilters: Array<string> = splitFilters(techFilter);

  return {
    company: companyFilter,
    initialTechFilter: techFilter,
    technologies: techFilters,
    year: yearFilter,
  };
};

/**
 * Function to build OptionsType from filters and page language exclusively for the ProjectsSearch component
 *
 * @param {CompanyFiltersType} filters:
 * @param {PageLanguage} language
 * @returns
 */
export const buildCategoryOptions = (filters: Array<CompanyFiltersType>, language: PageLanguage): OptionsType => {
  const sortByCompanyLikeTheFirst = (a: CompanyFiltersType, b: CompanyFiltersType) => {
    if (a.origin === "none") return -1;
    if (b.origin === "none") return 1;
    if (a.origin === "COMPANY") return -1;
    if (b.origin === "COMPANY") return 1;
    if (a.origin === "FREELANCER") return -1;
    if (b.origin === "FREELANCER") return 1;
    return 0;
  };

  return filters.sort(sortByCompanyLikeTheFirst).map((option) => ({
    value: option.name,
    suffix: option.origin === "COMPANY" ? SUFFIXES_FOR_OPTIONS.company[language] : undefined,
    icon: option.origin === "none" ? undefined : option.origin === "GITHUB" ? GitHubIcon : BusinessIcon,
  }));
};

/**
 * Function to build OptionsType from filters as string list exclusively for the ProjectsSearch component
 *
 * @param {CompanyFiltersType} filters:
 * @param {PageLanguage} language
 * @returns
 */
export const buildOptions = (filters: Array<string>): OptionsType =>
  filters.map((f) => ({
    value: f,
  }));
