import styled, { css } from 'styled-components';

import { cssEducationWidthMin580, cssEducationWidthMin380 } from './educationStyles';
import {
  cssProfileWidthMin1250,
  cssProfileWidthMin1024,
  cssProfileWidthMin940,
  cssProfileWidthMin600,
  cssProfileWidthMin500,
  cssProfileWidthMin420,
} from './profileStyles';
import { cssSkillsWidthMax600 } from './skillsStyles';

export const PROFILE_CSS_ID = 'Home-profile';

export const Container = styled.article`
  padding-top: 12vh;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;

  display: grid;
  grid-template-rows: auto;

  &,
  .Main-gaps {
    grid-gap: 5vh;
  }

  .Global-paper {
    padding: 4% 5%;

    .Paper-section-content {
      padding: 3%;
    }
    .Divider-component {
      margin: 2vh 0%;
    }
  }

  #${PROFILE_CSS_ID} {
    margin-top: 360px;
    border-color: ${({ theme }) => theme.colors.primary.main};

  }

  @media (max-width: 1250px) {
    max-width: 90%;

    ${cssProfileWidthMin1250}

    #${PROFILE_CSS_ID} {
      margin-top: 330px;
    }
  }

  @media (max-width: 1024px) {
    ${cssProfileWidthMin1024}
  }

  @media (max-width: 940px) {
    padding-top: 0;
    margin-top: 120px;
    ${cssProfileWidthMin940}
  }

  @media (max-width: 1024px) and (max-height: 700px), (max-width: 600px) {
    padding-top: 0;
    margin-top: 70px;
  }

  @media (max-width: 600px) {
    .Global-paper .Divider-component {
      margin: 4% 0%;
    }

    .Jobs-container {
      padding: 2%;
    }

    ${cssProfileWidthMin600}
    ${cssSkillsWidthMax600}
  }

  @media (max-width: 580px) {
    ${cssEducationWidthMin580}
  }

  @media (max-width: 500px) {
    ${cssProfileWidthMin500}
  }

  @media (max-width: 420px) {
    .Global-paper .Paper-section-content {
      padding: 0%;
    }
    ${cssProfileWidthMin420}
  }

  @media (max-width: 380px) {
    max-width: 100%;
    ${cssEducationWidthMin380}

    #${PROFILE_CSS_ID} {
      margin-top: 380px;
    }
  }
`;

export const SectionByCollunm = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .Global-paper {
    padding: 8% 10% !important;
    width: 100%;
  }

  @media (max-width: 600px) {
    grid-template-columns: auto;

    .Global-paper {
      padding: 4% 5% !important;
    }
  }
`;
