import React from "react";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Rating as Stars } from "@material-ui/lab";

import { AppTranslations } from "../../../assets/translations";
import { PageLanguage } from "../../../constants/types";
import { SubTopic as Text } from "../../Typography";
import { ContainerRating } from "../styles";

type Props = {
  technologyName: string;
  maxStars: number;
  pageLanguage: PageLanguage;
  projects: number;
  start: number;
  totalProjects: number;
};

const Rating: React.FC<Props> = ({ maxStars, technologyName, projects, start, totalProjects, pageLanguage }) => {
  const { TEXT_RANKING_PROJECTS } = AppTranslations[pageLanguage].components.ranking;

  const ValueCustom = () => <span className="Ranking-stars-value">{projects}</span>;

  return (
    <ContainerRating>
      <Text className="Ranking-title">{technologyName}</Text>

      <figure className="Ranking-stars">
        <Stars
          name="customized-empty"
          value={start}
          precision={0.5}
          max={maxStars}
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          disabled
        />
        <figcaption>
          <Text className="Ranking-stars-info">
            <ValueCustom />
            {` / ${totalProjects} ${TEXT_RANKING_PROJECTS}`}
          </Text>
        </figcaption>
      </figure>
    </ContainerRating>
  );
};

export default Rating;
