import ObjectUtils from './ObjectUtils';

type State = {
  data: any;
  error: boolean;
  loading: boolean;
};

const StateIsEmpty = (state: State): boolean => {
  let dataIsEmpty = false;
  if (ObjectUtils.isObjectEmpty(state)) return true;
  if (ObjectUtils.isObjectEmpty(state.data)) dataIsEmpty = true;
  else if (Array.isArray(state.data) && state.data.length === 0) dataIsEmpty = true;
  return dataIsEmpty && !state.loading && !state.error;
};

const StoreUtils = {
  StateIsEmpty,
};

export default StoreUtils;
