import React from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

const LoadingRanking: React.FC = (props) => {
  return (
    <Container>
      <Skeleton className="Ranking-loading" animation="wave" variant="rect" />
      <Skeleton className="Ranking-loading" animation="wave" variant="rect" />
      <Skeleton className="Ranking-loading" animation="wave" variant="rect" />
      <Skeleton className="Ranking-loading" animation="wave" variant="rect" />
      <Skeleton className="Ranking-loading" animation="wave" variant="rect" />
    </Container>
  );
};

export default LoadingRanking;

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  place-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;

  .Ranking-loading {
    width: 15%;
    height: 80px;
    margin: 10px;

    min-width: 90px;
  }

  @media (max-width: 600px) {
    min-height: 250;
  }
`;
