import React from 'react';
import { connect } from 'react-redux';

import { ROUTES } from '../../../../constants/routesConstants';
import { PageLanguage } from '../../../../constants/types';
import { ApplicationState } from '../../../../store';

import TreeMenuView from './TreeMenuView';

type OwnProps = {
  idCss?: string;
  pageLanguage: PageLanguage;
  routeId?: string;
  menuId: string;

  toHome: () => void;
  toAboutMe: () => void;
  toJobs: () => void;
  toPrjs: () => void;
  toSkill: () => void;
  toColleges: () => void;
  toCourses: () => void;
  toLanguages: () => void;
  toBlogs: () => void;
  toRecognitions: () => void;
};

type StateToProps = {
  totalProjects: number;
  totalJobs: number;
};

type Props = OwnProps & StateToProps;

const TreeMenu: React.FC<Props> = (props) => {
  const totalJobs = props.totalJobs === 0 ? '' : `${props.totalJobs}`;
  const totalProjects = props.totalProjects === 0 ? '' : `${props.totalProjects}`;

  const menuId = props.menuId || props.routeId;

  let selectedOption = menuId || '';
  const expandTreeOption = [selectedOption];

  switch (menuId) {
    case ROUTES.WORK_EXPERIENCE.id:
    case ROUTES.WORK_EXPERIENCE_SKILLS.id:
      expandTreeOption.push(ROUTES.WORK_EXPERIENCE.id);
      break;
    case ROUTES.WORK_EXPERIENCE_PROJECTS.id:
    case ROUTES.WORK_EXPERIENCE_PROJECTS_DETAILS.id:
    case ROUTES.WORK_EXPERIENCE_PROJECTS_FILTER.id:
      selectedOption = ROUTES.WORK_EXPERIENCE_PROJECTS.id;
      expandTreeOption.push(ROUTES.WORK_EXPERIENCE.id);
      break;
    case ROUTES.WORK_EXPERIENCE_JOBS.id:
    case ROUTES.WORK_EXPERIENCE_JOBS_JOB_DETAILS.id:
      selectedOption = ROUTES.WORK_EXPERIENCE_JOBS.id;
      expandTreeOption.push(ROUTES.WORK_EXPERIENCE.id);
      break;
    case ROUTES.EDUCATION.id:
    case ROUTES.EDUCATION_COLLEGES.id:
    case ROUTES.EDUCATION_COURSES.id:
    case ROUTES.EDUCATION_LANGUAGES.id:
    case ROUTES.EDUCATION_RECOGNITIONS.id:
      expandTreeOption.push(ROUTES.EDUCATION.id);
      break;
    default:
      break;
  }

  return (
    <TreeMenuView
      idCss={props.idCss}
      expanded={expandTreeOption}
      selected={selectedOption}
      pageLanguage={props.pageLanguage}
      totalJobs={totalJobs}
      totalProjects={totalProjects}
      toHome={props.toHome}
      toAboutMe={props.toAboutMe}
      toJobs={props.toJobs}
      toPrjs={props.toPrjs}
      toSkill={props.toSkill}
      toColleges={props.toColleges}
      toCourses={props.toCourses}
      toLanguages={props.toLanguages}
      toRecognitions={props.toRecognitions}
      toBlogs={props.toBlogs}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  totalProjects: state.projects.data[state.pageLanguage][state.workplace].length,
  totalJobs: state.jobs.data[state.pageLanguage].length,
});

export default connect(mapStateToProps)(TreeMenu);
