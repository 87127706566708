import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import { College, PageLanguage } from '../../../constants/types';
import Api from '../../../services/api';

import { loadSuccess, loadFailure } from './actions';

export interface Payload extends AnyAction {
  payload: PageLanguage;
}

type CollegeDTO = {
  courseName: string;
  schoolName: string;
  schoolLocation: string;
  courseStart: string;
  courseEnd: string;
};

export function* loadColleges(action: Payload) {
  try {
    const pageLanguage = action.payload;
    const response: Array<CollegeDTO> = yield Api.getColleges(pageLanguage);

    const colleges: Array<College> = response.map((value) => ({
      ...value,
      courseEnd: new Date(value.courseEnd),
      courseStart: new Date(value.courseStart),
    }));

    if (colleges.length < 1) throw new Error('Not found - empty');

    yield put(loadSuccess({ pageLanguage, colleges }));
  } catch (err) {
    yield put(loadFailure());
  }
}
