import { css } from "styled-components";

import { COLOR_30_PERCENT } from "./themes";

export const scroll = css`
  /* TO ---> FIREFOX */
  scrollbar-color: ${({ theme }) => theme.colors.primary.dark}
    ${({ theme }) =>
      theme.title === "light" ? theme.colors.background.primaryStrong : theme.colors.primary.light.concat(COLOR_30_PERCENT)} !important;
  scrollbar-width: thin !important;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    @media (max-width: 1024px) {
      width: 4px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.dark};
    border-color: ${({ theme }) => theme.colors.primary.dark};
    border-radius: 10px;
    border-width: 9px;

    @media (max-width: 1024px) {
      border-width: 1px;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) =>
      theme.title === "light" ? theme.colors.background.primaryStrong : theme.colors.primary.light.concat(COLOR_30_PERCENT)};
  }
`;

/**
 * CSS with gradient effect for background.
 * The line below should be implemented for everyone who uses this code snippet:
 *
 *  --color-1: ${({ theme }) => theme.colors.background.primary};
 *  --color-2: ${({ theme }) => theme.colors.background.primaryStrong};
 *  background: linear-gradient(-45deg, var(--color-1), var(--color-1), var(--color-2), var(--color-1));
 */
export const cssBackgroundGradientEffect = css`
  --b-main-color: ${({ theme }) => theme.colors.background.primary};
  --b-change-color: ${({ theme }) => theme.colors.background.primaryStrong};
  background: linear-gradient(-45deg, var(--b-main-color), var(--b-main-color), var(--b-change-color), var(--b-main-color));

  background-size: 400% 400%;
  animation: gradient 5s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const cssMaterailDialogCustom = css`
  .MuiDialog-paper {
    margin-top: 70px !important;
    @media (max-width: 1024px) and (max-height: 700px) {
      margin: 0px;
      margin-top: 0px !important;
      max-height: 90vh !important;
    }
  }

  .Global-imageview-dialog .MuiDialog-paper {
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    background: none;

    box-shadow: none;
    margin: 0 !important;

    img:hover {
      opacity: revert;
    }
    img {
      max-height: 70vh;
      max-width: 70vw;
    }

    @media (max-width: 360px) {
      button {
        width: 35px;
        height: 35px;
        svg {
          width: 0.9em;
          height: 0.9em;
        }
      }
    }
  }

  /* Margin for select material-ui with postion better. Example: Projects > Filter by */
  .MuiPaper-root {
    margin-top: 1%;
  }

  .MuiInputBase-root > .MuiSvgIcon-root:hover {
    cursor: pointer;
  }

  .MuiDialog-paper {
    width: 50%;
    max-width: 90%;
    max-height: 80vh;

    .MuiDialogTitle-root {
      color: ${({ theme }) => theme.colors.text.title};
    }

    @media (max-width: 1024px) {
      width: 90%;
    }
  }

  .Mui-selected,
  .MuiInputBase-input {
    color: ${({ theme }) => theme.colors.primary.light};
  }

  .MuiInputBase-root svg:hover,
  .MuiListItem-root:hover {
    color: ${({ theme }) => theme.colors.primary.dark} !important;
  }

  .MuiListItemIcon-root,
  .MuiIconButton-root {
    color: ${({ theme }) => theme.colors.text.paragraphSecondary};
  }

  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.colors.background.backgroundDisabled};
  }

  .MuiTouchRipple-root {
    color: ${({ theme }) => theme.colors.primary.dark} !important;
  }
`;

export const cssButtonSelected = css`
  background-color: ${({ theme }) => theme.colors.background.hover};
  color: ${({ theme }) => theme.colors.primary.light};

  svg,
  s path {
    fill: ${({ theme }) => theme.colors.primary.light};
  }
`;

export const cssButtonCssCustom = css`
  .Global-Button-center,
  .Global-Button-end,
  .Global-Button-text-center,
  .Global-Button-text-end {
    font-size: 0.65rem;
    width: fit-content;
    height: fit-content;
    display: inline-flex;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    .MuiButton-label {
      white-space: nowrap;
    }
    & > span {
      display: inline-flex;
    }

    border-color: ${({ theme }) => theme.colors.background.border};

    &,
    svg,
    path {
      color: ${({ theme }) => theme.colors.disable};
      fill: ${({ theme }) => theme.colors.disable};
    }

    &:hover {
      ${cssButtonSelected}
    }
  }

  .Global-button-icon {
    padding-right: 0;
    svg {
      margin-right: 2%;
      fill: rgb(0 0 0 / 0.25);
    }
  }

  .Global-Button-text-center,
  .Global-Button-text-end {
    display: block;
    border: none !important;
    text-transform: none !important;
    svg {
      padding-left: 1px;
    }
  }

  .Global-Button-center,
  .Global-Button-text-center {
    margin-left: auto;
    margin-right: auto;
  }

  .Global-Button-end,
  .Global-Button-text-end {
    margin-left: auto;
  }

  .Button-how-its-work {
    padding-right: revert !important;

    svg {
      margin-left: 1px;
    }
  }

  .Button-turnback,
  .Button-how-its-work {
    ${cssBackgroundGradientEffect};
  }
`;

export const GlobalEffectRipple = css`
  background: inherit radial-gradient(circle, transparent 1%, inherit 1%) center/150%;
  background-position: center;
  transition: background 0.8s;
  :active {
    background-color: ${({ theme }) => theme.colors.background.hover};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const cssGlobalCustom = css`
  .Global-paper {
    background: ${({ theme }) => theme.colors.background.primary};
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  }

  .Global-paper,
  #Commonpage-article,
  #Commonpage-aside-drawer .MuiPaper-root,
  #Header-container,
  #Footer {
    border: 4px solid ${({ theme }) => theme.colors.background.border};
  }

  .Global-border-right {
    border-right: solid 1px ${({ theme }) => theme.colors.background.border};
  }

  .Global-icon-svg {
    margin-right: 5%;
  }

  /* Necessary when modal is open outside body. */
  .Global-Languages-item-icon {
    margin-right: 10px;
    border-radius: 4px;
  }

  .Global-effect-ripple {
    ${GlobalEffectRipple}
  }

  .Loading-progress-circle {
    position: relative;
    margin-top: 45%;
  }

  .Global-hover {
    :hover {
      background-color: ${({ theme }) => theme.colors.background.hover};
    }
  }

  .Global-animate-true {
    -webkit-animation: rotation 2s infinite linear;

    @keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
      }
    }
  }
`;
