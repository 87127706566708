import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import { ROUTES } from '../../constants/routesConstants';
import { Profile as ProfileType, PageLanguage, DisplayType, RouteType } from '../../constants/types';
import { ApplicationState } from '../../store';
import * as ActionsProfile from '../../store/ducks/profile/actions';
import { debounce } from '../../Utils';
import DisplayUtils from '../../Utils/DisplayUtils';
import StoreUtils from '../../Utils/StoreUtils';

import { LoadingProfile, LoadingSideProfile } from './components/Loading';
import ProfileView from './Profile';
import { ProfilePositionType } from './styles';

import { Error } from '..';

type StateProps = {
  displayType: DisplayType;
  profileData: ProfileType | null;
  profileLoading: boolean;
  profileError: boolean;
  pageLanguage: PageLanguage;
  displayWidth: number;
  displayHeight: number;
};

type DispatchProps = {
  loadRequest(pageLanguage: PageLanguage): void;
};

type OwnProps = {
  className?: string;
  disableLastDivisor?: boolean;
  idCss: string;
  navigate?: (route: RouteType) => void;
  position?: ProfilePositionType;
  onRendered?: () => void;
};

type Props = StateProps & DispatchProps & OwnProps;

export const Profile: React.FC<Props> = (props) => {
  const [state, setState] = useState({ maxLayoutRenderReached: false, renders: 0 });

  const { position = 'line', disableLastDivisor, profileData, profileLoading, profileError } = props;
  const AGE = (new Date().getFullYear() - new Date(1994, 11, 27).getFullYear()).toString();
  const isSidePosition = position === 'side';
  const enableLastDivider = !disableLastDivisor && isSidePosition;
  const profileIsEmpty = StoreUtils.StateIsEmpty({ data: profileData, loading: profileLoading, error: profileError });
  const isMobilePhoneSM = DisplayUtils.isMobilePhoneSM(props.displayType);

  const loadProfile = () => props.loadRequest(props.pageLanguage);
  const navigateAboutMePage = () => props.navigate && props.navigate({ ...ROUTES.ABOUT_ME });

  const fixSizeProfile = () => {
    props.onRendered && props.onRendered();
    setState((prev) => ({ ...prev, maxLayoutRenderReached: prev.renders > 11, renders: prev.renders + 1 }));
  };

  useLayoutEffect(() => {
    if (props.onRendered && !state.maxLayoutRenderReached) debounce(fixSizeProfile, 50);
  });

  if (profileIsEmpty) loadProfile();

  if (profileError) {
    return (
      <Error
        idCss={props.idCss}
        className={`${props.className || ''} Profile-error`}
        onClickTryAgain={loadProfile}
        onRendered={props.onRendered}
      />
    );
  } else if (profileLoading || profileIsEmpty || !profileData) {
    if (isSidePosition) return <LoadingSideProfile idCss={props.idCss} className={props.className} onRendered={props.onRendered} />;
    return <LoadingProfile idCss={props.idCss} className={props.className} onRendered={props.onRendered} />;
  }

  return (
    <ProfileView
      ageNumber={AGE}
      className={props.className}
      enableLastDivider={enableLastDivider}
      idCss={props.idCss}
      isMobilePhoneSM={isMobilePhoneSM}
      isSidePosition={isSidePosition}
      navigateAboutMePage={navigateAboutMePage}
      pageLanguage={props.pageLanguage}
      profile={profileData}
      positionView={position}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  profileData: state.profile.data[state.pageLanguage],
  profileLoading: state.profile.loading,
  profileError: state.profile.error,
  pageLanguage: state.pageLanguage,
  displayType: state.displaySize.type,
  displayWidth: state.displaySize.width,
  displayHeight: state.displaySize.height,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(ActionsProfile, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
