import axios, { AxiosRequestConfig } from "axios";

import { PageLanguage } from "../constants/types";
import environments from "../environments";

import Auth from "./auth";

const API_SERVICES = environments.apiServices;
const PRIMARY_SERVER_HOST = API_SERVICES[0];

const RESPONSE_DELAY_IN_MILLISECONDS = 2500; // 2500 ms === 2.5 seconds

const ORIGIN_TIME = new Date().toTimeString();

const getConfig = (pageLanguage?: PageLanguage, headers = {}) => {
  const token = Auth.getToken();
  return {
    headers: {
      "Accept-Language": pageLanguage || "EN",
      Authorization: token.scope,
      "Origin-Zone": token.origin,
      "Origin-Time": ORIGIN_TIME,
      Client: "SITE",
      ...headers,
    },
  };
};

const getWithResponseDelay = async <T>(config: AxiosRequestConfig, path: string): Promise<T> => {
  const start = Date.now();

  const callback = (promiseCallback: () => void) => {
    const addDelay = Date.now() - start < RESPONSE_DELAY_IN_MILLISECONDS;
    return addDelay ? setTimeout(() => promiseCallback(), RESPONSE_DELAY_IN_MILLISECONDS) : promiseCallback();
  };

  return new Promise((resolve, reject) => {
    fetch<T>(PRIMARY_SERVER_HOST, path, config)
      .then((res) => callback(() => resolve(res)))
      .catch((e) => reject(e));
  });
};

const get = async <T>(config: AxiosRequestConfig, path: string): Promise<T> => fetch<T>(PRIMARY_SERVER_HOST, path, config);

const fetch = async <T>(baseURL: string, path: string, config: AxiosRequestConfig): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .create({ baseURL, timeout: RESPONSE_DELAY_IN_MILLISECONDS * 5 }) // wait response for until 12.5 seconds
      .get(path, config)
      .then((res) => resolve(res.data))
      .catch((e) => {
        /**
         * Handle with error on calling service trying to get data with other services until the last service available.
         */
        const nextServiceIndex = API_SERVICES.indexOf(baseURL) + 1;
        const lastService = nextServiceIndex === API_SERVICES.length;

        if (lastService) reject(e);
        else resolve(fetch(API_SERVICES[nextServiceIndex], path, config));
      });
  });
};

export default {
  getProjectOfGithub: (pageLanguage: PageLanguage) => get(getConfig(pageLanguage), "/projects/github"),
  getProjectsOfJobs: (pageLanguage: PageLanguage) => get(getConfig(pageLanguage), "/projects/job"),
  getProjectsAll: (pageLanguage: PageLanguage) => get(getConfig(pageLanguage), "/projects"),
  getProject: <T>(pageLanguage: PageLanguage, projectID: string) => get<T>(getConfig(pageLanguage), `/projects/${projectID}`),
  getPost: <T>(pageLanguage: PageLanguage, postId: string) => get<T>(getConfig(pageLanguage), `/blog/posts/${postId}`),

  getBlog: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/blog"),
  getColleges: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/colleges"),
  getHighlights: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/highlights"),
  getJobs: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/jobs"),
  getLanguages: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/languages"),
  getProfile: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/profile"),

  getCourses: () => getWithResponseDelay(getConfig(), "/courses"),

  getSkillsAll: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/skills"),
  getSkillsOfJobs: (pageLanguage: PageLanguage) => getWithResponseDelay(getConfig(pageLanguage), "/skills/jobs"),
  getSkillsOfGithub: () => getWithResponseDelay(getConfig(), "/skills/github"),

  getRankingAll: () => getWithResponseDelay(getConfig(), "/skills/ranking"),
  getRankingJobs: () => getWithResponseDelay(getConfig(), "/skills/ranking/jobs"),
  getRankingGithub: () => getWithResponseDelay(getConfig(), "/skills/ranking/github"),

  getOriginRoute: <T>(path: string) => get<T>(getConfig(undefined, { Path: path }), "/route"),
};
