import { AnyAction } from "redux";

import { ProjectDTO, PageLanguage, Workplace } from "../../../constants/types";

/*
 * Action Types
 */

export enum Types {
  LOAD_REQUEST = "@projects/LOAD_REQUEST",
  LOAD_SUCCESS = "@projects/LOAD_SUCCESS",
  LOAD_FAILURE = "@projects/LOAD_FAILURE",
  CHANGE_WORKPLACE = "@projects/CHANGE_WORKPLACE",
}

export type State = {
  readonly data: {
    [key in PageLanguage]: {
      [key in Workplace]: Array<ProjectDTO>;
    };
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  workplace: Workplace;
  pageLanguage: PageLanguage;
  projects: Array<ProjectDTO>;
};

export interface Action extends AnyAction {
  payload: Payload;
}
