import styled from "styled-components";

export const Container = styled.section`
  #Container-rating-title {
    margin: 0;
  }

  #Container-rating {
    padding: 1%;
    display: flex;
    flex-direction: row-reverse;
    place-content: space-evenly;
    list-style-type: none;
    flex-wrap: wrap;
  }
`;

export const ContainerRating = styled.li`
  width: fit-content;
  white-space: pre;
  margin: 5px;

  .MuiRating-root {
    padding-top: 2px;
    font-size: 1rem;

    opacity: 1 !important;
  }

  .MuiRating-root,
  .Ranking-stars-value {
    color: ${({ theme }) => theme.colors.primary.light};
    font-weight: bolder;
  }
  .Ranking-title {
    font-size: 0.7em;
    line-height: 1.4em;
    margin-left: 3%;
    margin-top: 2%;
    margin-bottom: 0;
  }

  .Ranking-stars {
    text-align: center;
    width: fit-content;
  }
  .Ranking-stars-info {
    margin-block-start: -1em;
    white-space: nowrap;
  }
`;
