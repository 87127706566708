import { Reducer } from "redux";

import { Types, State, Action } from "./types";

const INITIAL_STATE: State = {
  data: {
    jobs: null,
    github: null,
    both: null,
  },
  loading: false,
  error: false,
};

const RankingSkillsReducer: Reducer<State, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.LOAD_REQUEST:
      return loadRequest(state);
    case Types.LOAD_SUCCESS:
      return loadSuccess(state, action);
    case Types.LOAD_FAILURE:
      return loadFailure(state);
    default:
      return state;
  }
};

/*
 * Handlers
 */
const loadSuccess = (state: State, action: Action): State => ({
  ...state,
  loading: false,
  error: false,
  data: {
    ...state.data,
    [action.payload.workspace]: action.payload.ranking,
  },
});
const loadRequest = (state: State): State => ({
  ...state,
  loading: true,
  error: false,
});
const loadFailure = (state: State): State => ({
  ...state,
  loading: false,
  error: true,
});

export default RankingSkillsReducer;
