import { AnyAction } from "redux";

import { PageLanguage, Spotlight } from "../../../constants/types";

/*
 * Action Types
 */
export enum Types {
  LOAD_REQUEST = "@highlights/LOAD_REQUEST",
  LOAD_SUCCESS = "@highlights/LOAD_SUCCESS",
  LOAD_FAILURE = "@highlights/LOAD_FAILURE",
}

export type State = {
  readonly data: {
    EN: Array<Spotlight>;
    ES: Array<Spotlight>;
    PT: Array<Spotlight>;
    FR: Array<Spotlight>;
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  pageLanguage: PageLanguage;
  highlights: Array<Spotlight>;
};

export interface Action extends AnyAction {
  payload: Payload;
}
