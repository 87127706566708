import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';

import { DisplayType } from '../../../constants/types';
import DisplayUtils from '../../../Utils/DisplayUtils';

export enum DisplaySizeTypes {
  LOAD_REQUEST = '@display-size/UPDATE',
}

export type State = {
  width: number;
  height: number;
  type: DisplayType;
};

export interface Action extends AnyAction {
  payload: State;
}

export const ActionUpdateDisplaySize = (size: State) => action(DisplaySizeTypes.LOAD_REQUEST, size);

const INITIAL_STATE: State = {
  height: typeof window === 'object' ? window.innerHeight : 0,
  width: typeof window === 'object' ? window.innerWidth : 0,
  type: typeof window === 'object' ? DisplayUtils.getDeviceType(window.innerWidth, window.innerHeight) : 'xlg',
};

const DisplaySizeReducer: Reducer<State, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case DisplaySizeTypes.LOAD_REQUEST:
      return { ...action.payload };
    default:
      return state;
  }
};

export default DisplaySizeReducer;
