import { AnyAction } from "redux";

import { PageLanguage, Language } from "../../../constants/types";

/*
 * Action Types
 */
export enum Types {
  LOAD_REQUEST = "@languages/LOAD_REQUEST",
  LOAD_SUCCESS = "@languages/LOAD_SUCCESS",
  LOAD_FAILURE = "@languages/LOAD_FAILURE",
}

export type State = {
  readonly data: {
    EN: Array<Language>;
    ES: Array<Language>;
    PT: Array<Language>;
    FR: Array<Language>;
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  pageLanguage: PageLanguage;
  languages: Array<Language>;
};

export interface Action extends AnyAction {
  payload: Payload;
}
