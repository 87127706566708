import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';

import { PagesTranslations } from '../../../assets/translations';
import { PrintPageContent } from '../../../components';
import CollegesContainer from '../../../components/Colleges/CollegesContainer';
import Highlights from '../../../components/Highlights';
import LanguageContainer from '../../../components/Languages/LanguageContainer';
import { TitlePageContent as Title } from '../../../components/PrintPageContent';
import { ROUTES } from '../../../constants/routesConstants';
import { PageLanguage } from '../../../constants/types';
import { ApplicationState } from '../../../store';
import { setTimeoutOnClick } from '../../../Utils';
import BrowserUtils from '../../../Utils/BrowserUtils';

import LanguagesView from './LanguagesView';
import SpecializationView from './SpecializationView';

type Props = {
  routeId: string;
};

type StateProps = {
  pageLanguage: PageLanguage;
};

const ID_COLLEGES = 'Education-colleges';
const ID_COURSES = 'Education-courses';
const ID_LANGUAGES = 'Education-languages';
const ID_RECOGNITIONS = 'Education-recognitionss';

const EducationPage: React.FC<Props & StateProps> = ({ pageLanguage, routeId }) => {
  const { TEXT_TITLE_COLLEGE, TEXT_TITLE_SPECIALIZATION, TEXT_TITLE_LANGUAGES, TEXT_TITLE_RECOGNITIONS, COLLEGE } = PagesTranslations[
    pageLanguage
  ].EDUCATION;

  const moveTopTopic = (route: string) => {
    let idToScroll = '';
    if (route === ROUTES.EDUCATION.id || route === ROUTES.EDUCATION_LANGUAGES.id) idToScroll = ID_LANGUAGES;
    else if (route === ROUTES.EDUCATION_COLLEGES.id) idToScroll = ID_COLLEGES;
    else if (route === ROUTES.EDUCATION_COURSES.id) idToScroll = ID_COURSES;
    else if (route === ROUTES.EDUCATION_RECOGNITIONS.id) idToScroll = ID_RECOGNITIONS;

    if (idToScroll) {
      setTimeoutOnClick(() => BrowserUtils.scrollCenterId(idToScroll), 500);
    }
  };

  useEffect(() => moveTopTopic(routeId), [routeId]);

  return (
    <Fragment>
      <Section>
        <Title id={ID_LANGUAGES}>{TEXT_TITLE_LANGUAGES}</Title>
        <br />
        <LanguageContainer ComponentView={LanguagesView} />
      </Section>
      <br />
      <br />
      <Section>
        <Title id={ID_COLLEGES}>{TEXT_TITLE_COLLEGE}</Title>
        <br />
        <PrintPageContent pageContent={COLLEGE.content} />
      </Section>
      <br />
      <br />
      <Section>
        <Title id={ID_COURSES}>{TEXT_TITLE_SPECIALIZATION}</Title>
        <br />
        <CollegesContainer ComponentView={SpecializationView} />
      </Section>
      <br />
      <br />
      <Section>
        <Title id={ID_RECOGNITIONS}>{TEXT_TITLE_RECOGNITIONS}</Title>
        <br />
        <Highlights />
      </Section>
      <br />
    </Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  pageLanguage: state.pageLanguage,
  languages: state.languages.data[state.pageLanguage],
  colleges: state.colleges.data[state.pageLanguage],
});

export default connect(mapStateToProps)(EducationPage);

const Section = styled.section`
  width: 100%;
`;
