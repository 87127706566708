import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AnyAction, Dispatch } from 'redux';

import { Footer, Header } from './components';
import Routes from './routes';
import store from './store';
import { ActionUpdateDisplaySize } from './store/ducks/displaySize';
import { ThemeGlobalProvider } from './styles/provider';
import DisplayUtils from './Utils/DisplayUtils';

const App: React.FC = () => {
  localStorage.clear()
  useLayoutEffect(() => handleDisplaySize(store.dispatch), []);

  return (
    <Provider store={store}>
      <ThemeGlobalProvider>
        <BrowserRouter>
          <Header />
          <Routes />
          <Footer />
        </BrowserRouter>
      </ThemeGlobalProvider>
    </Provider>
  );
};

export default App;

const handleDisplaySize = (dispatch: Dispatch<AnyAction>) => {
  if (typeof window === 'object') {
    const updateSize = () =>
      dispatch(
        ActionUpdateDisplaySize({
          width: window.innerWidth,
          height: window.innerHeight,
          type: DisplayUtils.getDeviceType(window.innerWidth, window.innerHeight),
        })
      );
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }
};
