import React from 'react';

import { Fade as Transition } from '@material-ui/core';

type Props = {
  children: JSX.Element;
  start?: boolean;
  timeout?: number;
  timeStart?: number;
  timeExit?: number;
};

const TransitionEffect: React.FC<Props> = ({ children, start = true, timeout = 1000, timeStart, timeExit }) => {
  return (
    <Transition in={start} timeout={{ enter: timeStart || timeout, exit: timeExit || timeout }}>
      {children}
    </Transition>
  );
};

export default TransitionEffect;
