import { createGlobalStyle } from 'styled-components';

import { cssGlobalCustom, cssMaterailDialogCustom, cssButtonCssCustom, scroll } from './cssGlobalCustom';
import { FONT_FAMILY } from './themes';

export const GlobalStyle = createGlobalStyle`

  body {
    overflow-y: scroll;
    padding: 0 !important;

    background: ${({ theme }) => theme.colors.background.primaryStrong};

    @media (min-width: 1024px){
      /* Remover overlfow for bigger devices, because:
         when open model (select in projects page) elements fixed's
         were moving - width reflow effect. 
      */
     overflow-y: scroll !important;
    }
  }

  a {
    text-decoration: inherit;
    color: ${({ theme }) => theme.colors.text.hyperlink};
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  
  * {
    ${scroll}

    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
  }
  
  b {
    color: ${({ theme }) => theme.colors.text.subTitle};
  }

  /* Only Mozila Firefox */
  @supports (-moz-appearance:none) {
    br { 
      margin: 10px 0px;
     } 
  }

  :root {
    &,
    .MuiTab-root,
    .MuiPaginationItem-root,
    .MuiTypography-root,
    .MuiButton-root {
      font-family: ${FONT_FAMILY};
    }

    color: ${({ theme }) => theme.colors.text.paragraph};
    font-size: 21px;
    line-height: 32px;

    .Global-imageview-dialog{
      z-index: 9999 !important;
    }
    .MuiPopover-root{
      z-index: 9998 !important;
    }
    #Header-container{
      z-index: 9997;
    }
    .MuiDialog-root{
      z-index: 9996 !important;
    }
    #Commonpage-aside-drawer .MuiPaper-root {
      z-index: 9995;
    }
    .MuiDialog-paper {
      z-index: 9994 !important;
    }
    #Drawer-menu-open-background{
      z-index: 997 ;
    }

    .MuiDialog-container {
      ul {
        margin: 0 10%;
      }
    }

    ${cssGlobalCustom}
    ${cssMaterailDialogCustom}
    ${cssButtonCssCustom}

    @media (max-width: 1024px) and (max-height: 700px){ 
      #Drawer-menu-open-background{
        z-index: 9993 ;
      }
      #Header-container{
        z-index: 9992;
      }
    }

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 29px;
    }
  }
`;
