import React from 'react';
import { Fragment } from 'react';

import styled from 'styled-components';

import { PagesTranslations } from '../../../assets/translations';
import { PrintPageContent } from '../../../components';
import { SubtitleSmall as Text } from '../../../components/Typography';
import { Language, PageLanguage } from '../../../constants/types';

type Props = {
  pageLanguage: PageLanguage;
  languages: Array<Language>;
};

const LanguagesView: React.FC<Props> = (props: Props) => {
  const { LANGUAGES, TEXT_CERTIFICATE } = PagesTranslations[props.pageLanguage].EDUCATION;

  const Certificate = ({ language }: { language: Language }) => (
    <Fragment>
      {' ('}
      <a href={language.certificateLink} rel="noopener noreferrer" target="_blank" title={`Donwload ${TEXT_CERTIFICATE}`}>
        {TEXT_CERTIFICATE}
      </a>
      {')'}
    </Fragment>
  );

  const Complement = ({ language }: { language: Language }) => (
    <Fragment>
      {' - '}
      <b>
        {language.level}
        {language.certificateLink && <Certificate language={language} />}
      </b>
    </Fragment>
  );

  return (
    <Fragment>
      <PrintPageContent pageContent={LANGUAGES.content} />
      <Ul>
        {props.languages.map((language, i) => (
          <li key={i}>
            <Text style={{ marginBottom: 0 }}>
              {language.name}
              <Complement language={language} />
            </Text>
          </li>
        ))}
      </Ul>
    </Fragment>
  );
};

export default LanguagesView;

const Ul = styled.ul`
  width: 100%;
  padding: 0 5%;
`;
