import { AnyAction } from "redux";

import { Blog, PageLanguage } from "../../../constants/types";

/*
 * Action Types
 */
export enum Types {
  LOAD_REQUEST = "@blog/LOAD_REQUEST",
  LOAD_SUCCESS = "@blog/LOAD_SUCCESS",
  LOAD_FAILURE = "@blog/LOAD_FAILURE",
}

export type State = {
  readonly data: {
    EN: Array<Blog>;
    ES: Array<Blog>;
    PT: Array<Blog>;
    FR: Array<Blog>;
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  pageLanguage: PageLanguage;
  blog: Array<Blog>;
};

export interface Action extends AnyAction {
  payload: Payload;
}
