import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import { PageLanguage, Spotlight } from '../../../constants/types';
import Api from '../../../services/api';

import { loadSuccess, loadFailure } from './actions';

export interface Payload extends AnyAction {
  payload: PageLanguage;
}

export function* loadHighlights(action: Payload) {
  try {
    const pageLanguage = action.payload;
    const response: Array<Spotlight> = yield Api.getHighlights(pageLanguage);

    if (response.length < 1) throw new Error('Not found - empty');

    yield put(loadSuccess({ pageLanguage, highlights: response }));
  } catch (err) {
    yield put(loadFailure());
  }
}
